import React, { useState, useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { createScreenAsync } from 'src/app/api/screen';
import { ErrorHandling } from 'src/app/components/error-handler';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { getRangeFilterValueWithUnit } from 'src/app/components/table-difficult/components/filter-table-row/helpers';
import { AppTableFilter } from 'src/app/components/table-difficult/components/filters';
import { SettingsColumnsTable } from 'src/app/components/table-difficult/components/setting-columns-table';
import {
  selectScreeningsDictionary,
  setScreeningsDictionary,
  selectScreeningsTotal
} from 'src/app/slices/screeningSlice';

import styles from './CustomScreen.module.css';
import './style.css';

/**
 *
 * @param {object} props
 * @param {(e) => void} [props.handleError]
 * @returns {JSX.Element}
 */
const CustomScreenCreateComponent = ({ handleError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screeningDictionary = useSelector(selectScreeningsDictionary);
  const screeningsTotal = useSelector(selectScreeningsTotal);

  const [pagination, setPagination] = useState({
    limit: 10,
    index: 1,
    total: 0
  });

  const [columnsLayout, setColumnsLayout] = useState([]);
  const columnsRef = useRef([]);

  const updateColumns = useCallback((value, updateLayout = false) => {
    columnsRef.current = value;

    if (updateLayout) {
      setColumnsLayout(value);
    }
  }, []);

  const handleUpdateLayout = useCallback(() => {
    const cols = columnsRef.current;
    setColumnsLayout(cols);
    return cols;
  }, []);

  const handleSelectRowsPerPage = useCallback((val) => {
    setPagination((prev) => ({
      ...prev,
      limit: val
    }));
  }, []);

  const onChangeCustomColumns = useCallback(
    (newColumns) => {
      updateColumns(newColumns);
    },
    [updateColumns]
  );

  const handleSelectColumns = useCallback(
    (cols) => {
      updateColumns([...columnsRef.current, ...cols], true);
    },
    [updateColumns]
  );

  const handleSelectFilter = useCallback(
    (name, filter) => {
      if (filter) {
        const selectFilt = Object.values(filter).find((filt) => filt.name === name);

        const newCols = columnsRef.current.map((col) => {
          if (Object.prototype.hasOwnProperty.call(col, 'otherColumns')) {
            if (col.key === filter[0]?.columnName) {
              return { ...col, selectedFilter: selectFilt };
            } else {
              const checkedChildren = col.otherColumns.map((c) => {
                if (c.key === filter[0]?.columnName) {
                  return { ...c, selectedFilter: selectFilt };
                }
                return c;
              });
              return {
                ...col,
                otherColumns: checkedChildren
              };
            }
          } else if (col.key === filter[0]?.columnName) {
            return { ...col, selectedFilter: selectFilt };
          }
          return col;
        });

        updateColumns(newCols);
      }
    },
    [updateColumns]
  );

  const handleChangeFilter = useCallback(
    (columnName, value, unit = null, updateLayout = false) => {
      if (!columnName) {
        return;
      }

      let newCols = null;
      let updatedFilter = null;
      if (!unit) {
        newCols = columnsRef.current.map((col) => {
          if (Object.prototype.hasOwnProperty.call(col, 'otherColumns')) {
            if (col.key === columnName) {
              const newCol = { ...col, selectedFilter: { ...col.selectedFilter, value } };
              updatedFilter = { ...col.selectedFilter, value };
              return newCol;
            } else {
              const checkedChildren = col.otherColumns.map((c) => {
                if (c.key === columnName) {
                  updatedFilter = { ...c.selectedFilter, value };
                  return { ...c, selectedFilter: { ...c.selectedFilter, value } };
                }
                return c;
              });
              return {
                ...col,
                otherColumns: checkedChildren
              };
            }
          } else if (col.key === columnName) {
            updatedFilter = { ...col.selectedFilter, value };
            return { ...col, selectedFilter: { ...col.selectedFilter, value } };
          }
          return col;
        });
        updateColumns(newCols, updateLayout);
      } else {
        newCols = columnsRef.current.map((col) => {
          if (Object.prototype.hasOwnProperty.call(col, 'otherColumns')) {
            if (col.key === columnName) {
              updatedFilter = { ...col.selectedFilter, ...(value && { value }), unit };
              return { ...col, selectedFilter: { ...col.selectedFilter, ...(value && { value }), unit } };
            } else {
              const checkedChildren = col.otherColumns.map((c) => {
                if (c.key === columnName) {
                  updatedFilter = { ...c.selectedFilter, ...(value && { value }), unit };
                  return { ...c, selectedFilter: { ...c.selectedFilter, ...(value && { value }), unit } };
                }
                return c;
              });
              return {
                ...col,
                otherColumns: checkedChildren
              };
            }
          } else if (col.key === columnName) {
            updatedFilter = { ...col.selectedFilter, ...(value && { value }), unit };
            return { ...col, selectedFilter: { ...col.selectedFilter, ...(value && { value }), unit } };
          }
          return col;
        });
        updateColumns(newCols, updateLayout);
      }

      return updatedFilter;
    },
    [updateColumns]
  );

  const handleClearFilters = useCallback(() => {
    const newCols = columnsRef.current.map((col) => {
      if (Object.keys(col.filter).length > 1) {
        return { ...col, selectedFilter: null };
      }
      if (Object.prototype.hasOwnProperty.call(col, 'otherColumns')) {
        const newOthers = col.otherColumns.map((other) => {
          if (other.selectedFilter) {
            const { value, unit, ...newFilter } = other.selectedFilter;
            return { ...other, selectedFilter: newFilter };
          }
          return other;
        });
        const { value, unit, ...newFilter } = col.selectedFilter;
        return { ...col, otherColumns: newOthers, selectedFilter: newFilter };
      } else {
        const { value, unit, ...newFilter } = col.selectedFilter;
        return { ...col, selectedFilter: newFilter };
      }
    });

    updateColumns(newCols, true);
  }, [updateColumns]);

  const prepareData = useCallback(
    (val) => {
      const res = {};
      res.name = val.name;
      res.columns = {};
      res.filter = {};
      columnsLayout.forEach((col, index) => {
        res.columns[col.key] = {
          editable: true,
          visible: col.isVisible,
          order: index
        };
        if (col.selectedFilter?.value) {
          res.filter[col.key] = {
            name: col.selectedFilter.name,
            value: col.selectedFilter.unit
              ? getRangeFilterValueWithUnit(col.selectedFilter.value, col.selectedFilter.unit)
              : col.selectedFilter.value
          };
        }
        if (Object.prototype.hasOwnProperty.call(col, 'otherColumns')) {
          col.otherColumns.forEach((c) => {
            res.columns[c.key] = {
              editable: true,
              visible: c.isVisible,
              order: index
            };
            if (c.selectedFilter?.value) {
              res.filter[c.key] = {
                name: c.selectedFilter.name,
                value: c.selectedFilter.unit
                  ? getRangeFilterValueWithUnit(c.selectedFilter.value, c.selectedFilter.unit)
                  : c.selectedFilter.value
              };
            }
          });
        }
      }, []);
      return res;
    },
    [columnsLayout]
  );

  const handleSaveScreen = useCallback(
    (title) => {
      dispatch(createScreenAsync(prepareData(title)))
        // @ts-ignore
        .then((res) => {
          navigate(`/show-screen/${res.id}`);
          const newScreen = {
            id: res.id,
            type: res.type,
            name: res.name
          };
          dispatch(
            setScreeningsDictionary({
              screenings: [...screeningDictionary, newScreen],
              total: screeningsTotal + 1
            })
          );
        })
        .catch((err) => {
          if (err.message === 'error_screening_name_exists') {
            appMessage(typeMessage.ERROR, 'create_new_screen_error');
          } else {
            handleError(err);
          }
        });
    },
    [dispatch, navigate, prepareData, screeningDictionary, screeningsTotal, handleError]
  );

  return (
    <div className={`customScreen ${styles.page}`}>
      <div className={styles.paddings}>
        <h1 className={`${styles.title} page__title-uppercase _normal`}>{t('screen_title')}</h1>
      </div>
      <div className={styles.filterWrap}>
        <AppTableFilter isDefault={false} showLines={pagination.limit} onClick={handleSelectRowsPerPage}>
          <SettingsColumnsTable
            // isEditable={true}
            onSelectColumns={handleSelectColumns}
            data={columnsLayout}
            hasSave={false}
            hasSaveAs={true}
            onSaveAsScreen={handleSaveScreen}
            onSelectFilter={handleSelectFilter}
            onChangeFilter={handleChangeFilter}
            onClearFilters={handleClearFilters}
            onChangeCustomColumns={onChangeCustomColumns}
            updateLayout={handleUpdateLayout}
          />
        </AppTableFilter>
      </div>

      <div style={{ paddingBottom: '71px' }}></div>
    </div>
  );
};

export const CustomScreenCreate = () => {
  return (
    <ErrorHandling>
      <CustomScreenCreateComponent />
    </ErrorHandling>
  );
};
