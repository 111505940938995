import React from 'react';

import { Button, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AppInput from 'src/app/components/form/input';
import { AppTextarea } from 'src/app/components/form/text-area';
import { roles } from 'src/app/models/role';
import { selectProfile } from 'src/app/slices/profileSlice';

import styles from './InviteForm.module.css';

export const InviteForm = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const profile = useSelector(selectProfile);

  const inviteInitialValues = {
    email: '',
    recipient: '',
    extraInformation: '',
    roleId: roles.Guest
  };

  const handleSubmit = (val) => {
    form.resetFields();
    onSubmit(val);
  };

  return (
    <div className={styles.wrap}>
      <p className={styles.message}>{t('send_invitations_message')}</p>
      <Form
        name="invite"
        form={form}
        initialValues={inviteInitialValues}
        onFinish={handleSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <div className={styles.rowGroup}>
          <Form.Item
            name="recipient"
            rules={[
              {
                required: true,
                message: t('required_input')
              }
            ]}
          >
            <AppInput
              data-test="invitations_page_recipient"
              placeholder={t('invitation_recipient')}
              className="required"
            ></AppInput>
          </Form.Item>
          <Form.Item
            className={styles.email}
            name="email"
            rules={[
              {
                type: 'email',
                message: t('invalid_input')
              },
              {
                required: true,
                message: t('required_input')
              }
            ]}
          >
            <AppInput
              data-test="invitations_page_mail"
              className="required"
              placeholder={t('invitation_mail')}
            ></AppInput>
          </Form.Item>
        </div>
        {profile && profile.role?.title === 'Administrator' && (
          <>
            <Form.Item name="roleId">
              <Select placeholder="Role" className="mg-select" dropdownClassName="mg-select-dropdown">
                <Select.Option value={roles.Guest}>{t('guest')}</Select.Option>
                <Select.Option value={roles.Influencer}>{t('influencer')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item className={styles.extraInformation} name="extraInformation">
              <AppTextarea
                data-test="invitations_page_extra_information"
                placeholder={t('invitation_extra_information')}
              ></AppTextarea>
            </Form.Item>
          </>
        )}
        <Form.Item shouldUpdate>
          {() => (
            <Button
              data-test="invitations_page_send"
              htmlType="submit"
              className={`mg-btn _green ${styles.btn}`}
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldTouched('email')
              }
            >
              {t('send')}
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
