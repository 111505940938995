import React, { useEffect, useState, useCallback } from 'react';

import { Divider, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Subscription } from '../subscription';
import { SuccessfulPaymentModal } from '../successful-payment-modal';
import {
  loadProductsAsync,
  loadProductPricesAsync,
  subscribeToPriceAsync,
  updateSubscriptionAsync
} from 'src/app/api/product';
import { getProfileAsync } from 'src/app/api/profile';
import AppInput from 'src/app/components/form/input';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { ProductModel } from 'src/app/models/product';
import { getObjectWithPaidPeriod } from 'src/app/modules/products/helpers';
import { setSpinnerVisible } from 'src/app/slices/commonSlice';
import { selectProfile, setProfile } from 'src/app/slices/profileSlice';

import { ListOfPrices } from './listOfPrices';
import styles from './PeriodSubscription.module.css';

export const PeriodSubscription = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const profile = useSelector(selectProfile);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [prices, setPrices] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [promocode, setPromocode] = useState('');
  const [visibleSuccessfulModal, setVisibleSuccessfulModal] = useState(false);

  useEffect(() => {
    dispatch(setSpinnerVisible(true));
    dispatch(loadProductsAsync())
      // @ts-ignore
      .then((data) => {
        const { items } = data;
        const product = items.find((item) => item?.id === id);
        setSelectedProduct(new ProductModel(product));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, id]);

  useEffect(() => {
    const isActive = profile?.role?.title === 'Administrator';
    dispatch(
      loadProductPricesAsync(id, isActive, 'defaultScope', 0, 100, null, null, [
        { column: 'interval', direction: 'desc' },
        { column: 'interval_count', direction: 'desc' }
      ])
    )
      // @ts-ignore
      .then((data) => {
        const { items } = data;
        setPrices(
          items.map((item) => {
            return getObjectWithPaidPeriod({
              id: item?.id,
              interval: item?.interval,
              interval_count: item?.interval_count,
              price: item?.price,
              currency: item?.currency,
              isSelected: false
            });
          })
        );
        dispatch(setSpinnerVisible(false));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, id, profile?.role?.title]);

  const handleChangePrices = useCallback((prices) => {
    const price = prices.find((p) => p.isSelected);
    setSelectedPrice(price);
  }, []);

  const handleProceedToPayment = useCallback(() => {
    if (profile?.customer?.subscription) {
      dispatch(updateSubscriptionAsync(selectedPrice?.id, false))
        // @ts-ignore
        .then(() => {
          dispatch(getProfileAsync())
            // @ts-ignore
            .then((profile) => {
              dispatch(setProfile(profile));
              setVisibleSuccessfulModal(true);
            })
            .catch((e) => {
              appMessage(typeMessage.ERROR, e.message);
            });
        })
        .catch((e) => {
          if (e.message.includes('The price specified')) {
            appMessage(typeMessage.ERROR, 'contact_us_error');
          } else {
            appMessage(typeMessage.ERROR, e.message);
          }
        });
    } else {
      dispatch(subscribeToPriceAsync(selectedPrice?.id, promocode))
        // @ts-ignore
        .then((redirectUrl) => {
          window.location.replace(redirectUrl);
        })
        .catch((e) => {
          if (e.message.includes('The price specified')) {
            appMessage(typeMessage.ERROR, 'contact_us_error');
          } else {
            appMessage(typeMessage.ERROR, e.message);
          }
        });
    }
  }, [dispatch, profile?.customer?.subscription, promocode, selectedPrice?.id]);

  const handleOnChangePromocode = useCallback((currentValue) => {
    setPromocode(currentValue);
  }, []);

  const handleOnOkSuccessfulModal = useCallback(() => {
    setVisibleSuccessfulModal(false);
    navigate('/');
  }, [navigate]);

  return (
    <div className={`page ${styles.wrap}`}>
      <div className={styles.subscription}>
        <Subscription subscription={selectedProduct} />
      </div>

      <div className={styles.period}>
        <h3>{t('period_subscription_title')}</h3>
        <Divider className={styles.divider} />
        <p className={styles.description}>{t('period_subscription_text')}</p>
        <ListOfPrices productPrices={prices} onChange={handleChangePrices} />
        <div className={styles.payment}>
          <p>{t('add_promo')}</p>
          <AppInput value={promocode} onChange={handleOnChangePromocode} />
          <Button
            data-test="proceed_to_payment"
            disabled={!selectedPrice}
            onClick={handleProceedToPayment}
            className={`mg-btn`}
          >
            {t('proceed_to_payment_btn')}
          </Button>
        </div>
      </div>

      <SuccessfulPaymentModal
        visible={visibleSuccessfulModal}
        onClick={handleOnOkSuccessfulModal}
        text={t('success_change_subscription_text')}
      />
    </div>
  );
};
