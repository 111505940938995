import React, { useMemo, useState } from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SelectSvg } from 'src/assets/svg/select-arrow.svg';
import './styles.css';

const { Option } = Select;
/**
 * @param {object} props
 * @param {string} [props.placeholder]
 * @param {string} [props.label]
 * @param {any} props.options
 * @param {any} props.value string, number or null
 * @param {(value: any) => void} props.onChange
 */
export const AppSelect = ({ placeholder, label, options, value, onChange, ...rest }) => {
  const { t } = useTranslation();

  const componentId = useMemo(() => Date.now().toString(16), []);
  const [open, setOpen] = useState(false);

  const handleDropdownVisibleChange = (open) => {
    setOpen(open);
  };
  return (
    <div className="mg-select__wrap">
      <label htmlFor={componentId} className="mg-select__label">
        {t(label)}
      </label>

      <Select
        id={componentId}
        value={value}
        placeholder={placeholder}
        className="mg-select"
        dropdownClassName="mg-select-dropdown"
        onDropdownVisibleChange={handleDropdownVisibleChange}
        onChange={onChange}
        showArrow={true}
        getPopupContainer={(trigger) => trigger.parentElement}
        optionLabelProp="label"
        {...rest}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={t(option.label)}>
            {option?.customOptionTitle || t(option.label)}
          </Option>
        ))}
        <SelectSvg className={`mg-select-arrow ${open ? '_open' : ''}`} />
      </Select>
    </div>
  );
};
