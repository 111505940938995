import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Table, Pagination, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { requestSupportAsync } from 'src/app/api/support';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { selectNames } from 'src/app/slices/screeningSlice';
import { ReactComponent as QualityIcon } from 'src/assets/svg/quality.svg';
import { ReactComponent as QuestionCircleIcon } from 'src/assets/svg/question.svg';
import { ReactComponent as DiagramIcon } from 'src/assets/svg/table/diagram.svg';
import { ReactComponent as SvgArrow } from 'src/assets/svg/table/scroll-arrow.svg';

import { AppTableCell } from './components/cell/index';
import { ContactSupport } from './components/contact-support-modal';
import { TableSortOrder } from './components/sort-order/index';
import { AppTableSort } from './components/sort/index';

import './styles.css';

export const FilterTypes = {
  singleText: 'singleText',
  multipleText: 'multipleText',
  singleNumber: 'singleNumber',
  multipleNumber: 'multipleNumber'
};
/* @params
  columns = [
    {
      title: "Цена",
      key: 'cost',
      dataIndex: 'cost',
      filterType: filterTypes.singleText,
      sorter?: true,
      filtered?: true,
      sortTitle?: 'costValue',
      withChart?: true,
      filterTitle?: ['costValue'],
      chartData?: {},
      sortValue?: null,
      editableCellComponent?: ReactComponent,
      onChangeCell?: ReactComponent
    }
  ]

  sort: [{column: 'createdDate', direction: 'asc' | 'desc'}]
  filter: {cost: 1000}
*/
/**
 * @param {object} props
 * @param {ScreenTypes.TableColumn[]} props.columns
 * @param {object[]} props.listOfData
 * @param {ColumnTypes.ColumnSort[]} props.sort
 * @param {any} props.padding
 * @param {(e: unknown) => void} props.onChange
 * @param {ColumnTypes.Pagination} props.pagination
 * @param {(record: object) => void} props.onSelectRow
 * @param {({direction, column, sortTitle}) => void} props.onSort
 * @param {string} props.dataKey
 * @param {() => void} props.onSortOrderEnd
 * @param {() => void} props.onDeleteSort
 * @param {(page: number, pageSize: number) => void} props.onChangePagination
 * @param {boolean} props.isLoading
 */
export const AppDificultTable = ({
  columns,
  listOfData,
  sort,
  padding,
  onChange,
  pagination,
  onSelectRow,
  onSort,
  dataKey,
  onSortOrderEnd,
  onDeleteSort,
  onChangePagination,
  isLoading
}) => {
  /** @type {UseHelper.UseStateType<ScreenTypes.TableColumnInternal[]>} */
  const [tableCols, setTableCols] = useState([]);
  /** @type {UseHelper.UseRefType<HTMLElement>} */
  const scrollbarsRef = useRef();
  const [isVisibleScroll, setIsVisibleScroll] = useState(false);
  const [isSupportVisible, setIsSupportVisible] = useState(false);
  const { t } = useTranslation();
  /** @type {UseHelper.UseStateType<ScreenTypes.SupportData>} */
  const [supportData, setSupportData] = useState({
    cik: null,
    tiker: null,
    data: null
  });

  console.log('AppDificultTable, rendered');
  console.log('pagination, sort, dataKey', pagination, sort, dataKey);

  const names = useSelector(selectNames);

  const dispatch = useDispatch();

  const handleScroll = () => {
    // @ts-ignore
    setIsVisibleScroll((prev) => scrollbarsRef?.current?.classList.contains('ps--active-x'));
  };

  useEffect(() => {
    handleScroll();
  }, [scrollbarsRef?.current?.classList.length]);

  const handleResize = () => {
    setTimeout(() => {
      handleScroll();
    }, 0);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // @ts-ignore
  //   dispatch(loadNames).then((data) => {
  //     setNames(data);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleClickScroll = (direction) => {
    if (direction === 'right') {
      scrollbarsRef.current.scrollLeft += 20;
    } else {
      scrollbarsRef.current.scrollLeft -= 20;
    }
  };

  const components = {
    body: {
      cell: AppTableCell
    },
    table: (props) => (
      <PerfectScrollbar
        options={{ minScrollbarLength: 200, maxScrollbarLength: 450 }}
        className={'scroll-container'}
        containerRef={(el) => (scrollbarsRef.current = el)}
      >
        <table className={props.className}>{props.children}</table>
      </PerfectScrollbar>
    )
  };

  const sortTable = useCallback(
    (key, sortTitle) => {
      const sortElem = sort.find((srt) => srt.column === key);
      onSort({
        direction: sortElem && sortElem.direction === 'ASC' ? 'DESC' : 'ASC',
        column: key,
        sortTitle: t(sortTitle)
      });
    },
    [onSort, sort, t]
  );

  const checkSortActive = useCallback(
    (col) => {
      return col.sorted && !!sort.find((srt) => srt.column === (col.sortTitle || col.key));
    },
    [sort]
  );

  /** @type {ScreenFuncs.HandleOpenSupport} */
  const handleOpenSupport = useCallback(
    (cik, ticker, dataIndex, data) => {
      setIsSupportVisible((prev) => !prev);
      if (cik) {
        const column = columns.find((col) => col.dataIndex === dataIndex);
        const c = column.columnTitle || column.title;
        setSupportData({ cik, ticker, column: c, data });
      } else {
        setSupportData({ cik: null, ticker: null, column: null, data: null });
      }
    },
    [columns]
  );

  /** @type {(val: ScreenTypes.SupportData) => void} */
  const handleSubmitSupport = (val) => {
    supportData.message = val.message;
    const msg = JSON.stringify(supportData);
    dispatch(requestSupportAsync('Problem with data', msg))
      // @ts-ignore
      .then(() => {
        appMessage(typeMessage.SUCCESS, 'support_success_message');
        setIsSupportVisible((prev) => !prev);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  };

  useEffect(() => {
    const cols = [];
    columns.forEach((col) => {
      if (!col.isVisible) {
        return;
      }

      const anchor = col.group ? names?.find((name) => name.name_id.toString() === col.group)?.name : '';
      cols.push({
        ...col,
        title: (
          <div className="mg-difficult-table-th">
            <span className="mg-difficult-table-th__title">
              <Tooltip overlayClassName="mg-tooltip" title={t(col.columnTitle) || t(col.title)}>
                <div
                  // @ts-ignore
                  onClick={col.sorted ? () => sortTable(col.sortTitle || col.key, col.columnTitle || col.title) : null}
                >
                  <div className="mg-difficult-table-th__title_name">
                    <span>{t(col.columnTitle) || t(col.title)}</span>
                    {col.type === 'Quality' && (
                      <div>
                        <QualityIcon />
                      </div>
                    )}
                    {col.type?.includes('diagram') && (
                      <div>
                        <DiagramIcon />
                      </div>
                    )}
                  </div>
                  {col.group && (
                    <Link
                      to={`/resources/articles/definitions#${anchor || ''}`}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <QuestionCircleIcon />
                    </Link>
                  )}
                  {col.sorted ? (
                    <AppTableSort
                      // @ts-ignore
                      sort={sort.find((srt) => srt.column === (col.sortTitle || col.key))}
                      isActive={checkSortActive(col)}
                    />
                  ) : null}
                </div>
              </Tooltip>
            </span>
          </div>
        ),
        filtered: false,
        sorter: false,
        onCell: (record) => ({
          record,
          title: col.columnTitle || col.title,
          // chartData: col.chartData,
          dataIndex: col.dataIndex,
          onOpenContactSupport: handleOpenSupport,
          AdditionalEventsComponent: col.additionalEventsComponent,
          AdditionalEventsFirstComponent: col.additionalEventsFirstComponent,
          onChangeAdditionalEvent: col.onChangeAdditionalEvent,
          onChangeAdditionalEventChange: col.onChangeAdditionalEventChange,
          withChart: col.withChart,
          EditableCellComponent: col.editableCellComponent,
          onChangeCell: col.onChangeCell,
          onPressEnterCell: col.onPressEnterCell,
          onBlurCell: col.onBlurCell,
          editingDefault: col.editingDefault
        })
      });
    });
    setTableCols(cols);
  }, [checkSortActive, columns, handleOpenSupport, names, sort, sortTable, t]);

  return (
    <div className="mg-difficult-table__wrap">
      {sort && sort.length ? (
        <TableSortOrder
          onSortEnd={onSortOrderEnd}
          isVisibleScroll={isVisibleScroll}
          sort={sort}
          onDeleteSort={onDeleteSort}
        />
      ) : (
        ''
      )}
      {isVisibleScroll ? (
        <>
          <Button
            data-test="custom_screen_page_table_difficult_scroll_right"
            className={`mg-btn _text scroll-button _right ${!sort.length && '_without-sort'}`}
            onClick={() => handleClickScroll('right')}
          >
            <SvgArrow />
          </Button>
          <Button
            data-test="custom_screen_page_table_difficult_scroll_left"
            className={`mg-btn _text scroll-button _left ${!sort.length && '_without-sort'}`}
            onClick={() => handleClickScroll('left')}
          >
            <SvgArrow />
          </Button>{' '}
        </>
      ) : (
        ''
      )}

      <Table
        style={{ padding: padding }}
        onRow={(record) => {
          return {
            onClick: () => {
              if (onSelectRow) {
                onSelectRow(record);
              }
            }
          };
        }}
        scroll={{ x: 1500 }}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => {
          return record[dataKey || 'id'] || Math.random().toString(16).slice(2);
        }}
        onChange={onChange}
        components={components}
        columns={tableCols}
        dataSource={listOfData}
        className={`mg-difficult-table ${isVisibleScroll ? '_scroll-visible' : ''}`}
        tableLayout={'fixed'}
      />
      {pagination.total > pagination.limit ? (
        <div className="mg-table__pagination-wrap">
          <Pagination
            pageSize={pagination.limit}
            total={pagination.total}
            current={pagination.index}
            onChange={onChangePagination}
            hideOnSinglePage={true}
          />
        </div>
      ) : (
        ''
      )}
      <ContactSupport
        visible={isSupportVisible}
        onCancel={handleOpenSupport}
        onCreate={handleSubmitSupport}
        supportData={supportData}
      />
    </div>
  );
};
