import React from 'react';

import { useTranslation } from 'react-i18next';

import { TermsList } from '../terms-text-list';
import { TextBlock } from '../text-block';
import { TextListBlock } from '../text-list-block';

import styles from './TermsAndConditions.module.css';

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className={`page ${styles.termsAndConditions}`}>
      <h1 className="page__title-uppercase">{t('title_terms_conditions')}</h1>
      <p dangerouslySetInnerHTML={{ __html: t('terms_introduction') }} style={{ marginBottom: '36px' }}></p>
      <TextListBlock
        title={t('terms_title_block_what_we_do')}
        beforeText={t('terms_text_block_what_we_do_1')}
        listItems={[
          t('terms_what_we_do_list_item_1'),
          t('terms_what_we_do_list_item_2'),
          t('terms_what_we_do_list_item_3')
        ]}
        afterText={t('terms_text_block_what_we_do_2')}
        setInnerHTML
      />
      <TextBlock title={t('title_block_terms_how_provide')} text={t('text_block_terms_how_provide')} setInnerHTML />
      <TextBlock title={t('title_block_terms_how_agree')} text={t('text_block_terms_how_agree')} setInnerHTML />
      <TextBlock
        title={t('title_block_terms_how_communicate')}
        text={t('text_block_terms_how_communicate')}
        setInnerHTML
      />
      <TextBlock title={t('title_block_terms_how_get_copy')} text={t('text_block_terms_how_get_copy')} setInnerHTML />
      <div className={styles.termsList}>
        <ol>
          <TermsList
            title={t('title_block_terms_our_services')}
            beforeText={[
              t('terms_our_services_list_title_1'),
              t('terms_our_services_list_title_2'),
              t('terms_our_services_list_title_3'),
              t('terms_our_services_list_title_4')
            ]}
            listItems={[
              [
                t('terms_our_services_list_item_1'),
                t('terms_our_services_list_item_2'),
                t('terms_our_services_list_item_3'),
                t('terms_our_services_list_item_4'),
                t('terms_our_services_list_item_5')
              ],
              [t('terms_our_services_list_item_6')],
              [t('terms_our_services_list_item_7')],
              [
                t('terms_our_services_list_item_8'),
                t('terms_our_services_list_item_9'),
                t('terms_our_services_list_item_10'),
                t('terms_our_services_list_item_11')
              ]
            ]}
            nestedListItems={[
              null,
              [
                t('terms_our_services_list_item_6_1'),
                t('terms_our_services_list_item_6_2'),
                t('terms_our_services_list_item_6_3'),
                t('terms_our_services_list_item_6_4'),
                t('terms_our_services_list_item_6_5')
              ],
              null,
              null
            ]}
          />
          <TermsList
            title={t('title_block_terms_use_services')}
            beforeText={[
              t('terms_use_services_list_title_1'),
              null,
              t('terms_use_services_list_title_3'),
              null,
              t('terms_use_services_list_title_5'),
              null,
              null,
              null,
              t('terms_use_services_list_title_9'),
              t('terms_use_services_list_title_10')
            ]}
            listItems={[
              [t('terms_use_services_list_item_1')],
              [t('terms_use_services_list_item_2')],
              [t('terms_use_services_list_item_3')],
              [t('terms_use_services_list_item_4')],
              [t('terms_use_services_list_item_5')],
              [t('terms_use_services_list_item_6')],
              [t('terms_use_services_list_item_7')],
              [t('terms_use_services_list_item_8')],
              [
                t('terms_use_services_list_item_9'),
                t('terms_use_services_list_item_10'),
                t('terms_use_services_list_item_11'),
                t('terms_use_services_list_item_12'),
                t('terms_use_services_list_item_13'),
                t('terms_use_services_list_item_14')
              ],
              [
                t('terms_use_services_list_item_15'),
                t('terms_use_services_list_item_16'),
                t('terms_use_services_list_item_17')
              ]
            ]}
            nestedListItems={[
              null,
              [t('terms_use_services_list_item_2_1'), t('terms_use_services_list_item_2_2')],
              [
                t('terms_use_services_list_item_3_1'),
                t('terms_use_services_list_item_3_2'),
                t('terms_use_services_list_item_3_3'),
                t('terms_use_services_list_item_3_4'),
                t('terms_use_services_list_item_3_5'),
                t('terms_use_services_list_item_3_6')
              ],
              null,
              null,
              [
                t('terms_use_services_list_item_6_1'),
                t('terms_use_services_list_item_6_2'),
                t('terms_use_services_list_item_6_3'),
                t('terms_use_services_list_item_6_4')
              ],
              [
                t('terms_use_services_list_item_7_1'),
                t('terms_use_services_list_item_7_2'),
                t('terms_use_services_list_item_7_3')
              ],
              null,
              null
            ]}
          />
          <TermsList
            title={t('title_block_terms_fees')}
            beforeText={[
              t('terms_fees_list_title_1'),
              null,
              t('terms_fees_list_title_3'),
              null,
              null,
              t('terms_fees_list_title_6'),
              t('terms_fees_list_title_7')
            ]}
            listItems={[
              [t('terms_fees_list_item_1')],
              [t('terms_fees_list_item_2')],
              [t('terms_fees_list_item_3')],
              [t('terms_fees_list_item_4')],
              [t('terms_fees_list_item_5')],
              [t('terms_fees_list_item_6'), t('terms_fees_list_item_7'), t('terms_fees_list_item_8')],
              [t('terms_fees_list_item_9'), t('terms_fees_list_item_10')]
            ]}
            nestedListItems={[
              [t('terms_fees_list_item_1_1'), t('terms_fees_list_item_1_2'), t('terms_fees_list_item_1_3')],
              null,
              null,
              [t('terms_fees_list_item_4_1'), t('terms_fees_list_item_4_2'), t('terms_fees_list_item_4_3')],
              null,
              null,
              null
            ]}
          />
          <TermsList
            title={t('title_block_terms_communication')}
            beforeText={[null]}
            listItems={[
              [
                t('terms_communication_list_item_1'),
                t('terms_communication_list_item_2'),
                t('terms_communication_list_item_3')
              ]
            ]}
            nestedListItems={[null]}
          />
          <TermsList
            title={t('title_block_terms_privacy')}
            beforeText={[null]}
            listItems={[
              [
                t('terms_privacy_list_item_1'),
                t('terms_privacy_list_item_2'),
                t('terms_privacy_list_item_3'),
                t('terms_privacy_list_item_4')
              ]
            ]}
            nestedListItems={[null]}
          />
          <TermsList
            title={t('title_block_terms_duration')}
            beforeText={[null, t('terms_duration_list_title_2')]}
            listItems={[
              [
                t('terms_duration_list_item_1'),
                t('terms_duration_list_item_2'),
                t('terms_duration_list_item_3'),
                t('terms_duration_list_item_4')
              ],
              [t('terms_duration_list_item_5')]
            ]}
            nestedListItems={[null, null]}
          />
          <TermsList
            title={t('title_block_terms_liability')}
            beforeText={[null]}
            listItems={[
              [
                t('terms_liability_list_item_1'),
                t('terms_liability_list_item_2'),
                t('terms_liability_list_item_3'),
                t('terms_liability_list_item_4')
              ]
            ]}
            nestedListItems={[null]}
          />
          <TermsList
            title={t('title_block_terms_intellectual_property')}
            beforeText={[null]}
            listItems={[
              [
                t('terms_intellectual_property_list_item_1'),
                t('terms_intellectual_property_list_item_2'),
                t('terms_intellectual_property_list_item_3'),
                t('terms_intellectual_property_list_item_4'),
                t('terms_intellectual_property_list_item_5'),
                t('terms_intellectual_property_list_item_6'),
                t('terms_intellectual_property_list_item_7'),
                t('terms_intellectual_property_list_item_8')
              ]
            ]}
            nestedListItems={[null]}
          />
          <TermsList
            title={t('title_block_terms_governing_law')}
            beforeText={[null]}
            listItems={[[t('terms_governing_law_list_item_1'), t('terms_governing_law_list_item_2')]]}
            nestedListItems={[null]}
          />
        </ol>
      </div>
    </div>
  );
};
