import React, { useCallback, useState } from 'react';

import { ErrorModal } from '../error-modal';
import { appMessage } from '../message';
import { typeMessage } from '../message/constants';

export const ErrorHandling = ({ children }) => {
  /** @type {UseHelper.UseStateType<Errors.RequestError>} */
  const [errorModalProps, setErrorModalProps] = useState({ status: 400, message: 'Error', data: {} });
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const handleError = useCallback((e) => {
    if (e.message === 'canceled') {
      appMessage(typeMessage.ERROR, e.message, e.data);
    } else if (e.message.includes('restriction') || e.message.includes('error') || e.message.includes('Wiki')) {
      setErrorModalProps(e);
      setErrorModalVisible(true);
    } else {
      appMessage(typeMessage.ERROR, e.message, e.data);
    }
  }, []);

  const NewChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      handleError
    });
  });

  return (
    <>
      {NewChildren}
      <ErrorModal isVisible={errorModalVisible} setInvisible={() => setErrorModalVisible(false)} e={errorModalProps} />
    </>
  );
};
