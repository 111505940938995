import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AddToPortfolioModal } from '../portfolio/components/add-to-portfolio-modal';
import { MenuRowsPerPage } from '../portfolio/components/menu-rows-per-page';
import { getElementOfTableValue } from '../show-screen/helpers';
import { deleteFromIdeasAsync, loadIdeasInScreeningAsync } from 'src/app/api/ideas';
import { createPortfolioTickerAsync, loadPortfoliosAsync } from 'src/app/api/portfolio';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { RestrictionModal } from 'src/app/components/restriction-modal';
import { AppSpinner } from 'src/app/components/spinner/spinner-component';
import { AppDificultTable } from 'src/app/components/table-difficult';
import { selectProfile } from 'src/app/slices/profileSlice';
// import { selectScreeningsDictionary } from 'src/app/slices/screeningSlice';

import { DeleteModal } from './components/delete-modal';
import { RowMenuScreenIdeas } from './components/table-row-screen/rowMenu';
import styles from './Ideas.module.css';

export const Ideas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSpinnerVisible, setIsSpinnerVisible] = useState(true);

  // const screenings = useSelector(selectScreeningsDictionary);
  const profile = useSelector(selectProfile);

  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isResctrictionModalVisible, setIsResctrictionModalVisible] = useState(false);

  const [sort, setSort] = useState([{ column: 'company_name', direction: 'ASC', sortTitle: 'Company name' }]);
  const [pagination, setPagination] = useState({
    limit: 10,
    index: 1,
    total: 0
  });

  const [currentIdea, setCurrentIdea] = useState(null);
  const [deleteIdeaModalVisible, setDeleteIdeaModalVisible] = useState(false);
  const [infoCol, setInfoCol] = useState({});
  const [portfolios, setPortfolios] = useState(null);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [addToPortfolioModalVisible, setAddToPortfolioModalVisible] = useState(false);

  const isFirstLoading = useRef(true);

  const prepareColumns = useCallback((columns) => {
    // const id = screenings.find((item) => item.name === 'Default screening')?.id;
    const columnsKeys = Object.keys(columns).sort((a, b) => columns[a].order - columns[b].order);
    return columnsKeys.map((key, index) => {
      const withChart = columns[key].title.includes('diagram');
      return {
        group: columns[key].group || null,
        groupName: columns[key].groupName || null,
        type: columns[key].group ? columns[key].title : null,
        title: columns[key].group ? `${columns[key].groupName} (${columns[key].title})` : columns[key].title,
        key: key,
        dataIndex: key,
        sorted: !key.includes('diagram'),
        filtered: false,
        withChart,
        render:
          key === 'company_name'
            ? (_, company) => {
                return (
                  <Tooltip overlayClassName="mg-tooltip" title={company?.company_name}>
                    <Link className={styles.companyLink} to={`/company/overview/${company?.cik}`}>
                      {company?.company_name}
                    </Link>
                  </Tooltip>
                );
              }
            : getElementOfTableValue,
        fixed: index === 0 ? 'left' : null,
        isVisible: columns[key].visible,
        filter: { columnName: key },
        editable: columns[key].editable,
        index: index
      };
    });
  }, []);

  const loadScreenFromIdeas = useCallback(() => {
    setIsSpinnerVisible(true);
    if (profile) {
      dispatch(loadIdeasInScreeningAsync(pagination.index - 1, pagination.limit, sort))
        // @ts-ignore
        .then((data) => {
          let { items, total, columns } = data;

          const cols = prepareColumns(columns);
          setColumns(cols);
          if (!items) {
            items = [];
          }
          setPagination((prevState) => {
            if (!items.length) {
              return {
                ...prevState,
                index: pagination.index > 1 ? pagination.index - 1 : 1
              };
            }
            return {
              ...prevState,
              total
            };
          });
          setTableData(items);
          setIsSpinnerVisible(false);
        })
        .catch((e) => {
          setIsSpinnerVisible(false);
          if (e.message.includes('The daily limit has been reached')) {
            setIsResctrictionModalVisible(true);
          } else if (e.message !== 'canceled') {
            appMessage(typeMessage.ERROR, e.message);
          }
        });
    }
  }, [dispatch, pagination.index, pagination.limit, prepareColumns, profile, sort]);

  const handleOnChangeVisibleDeleteIdeaModal = useCallback(() => {
    setDeleteIdeaModalVisible((prev) => !prev);
  }, []);

  const handleOnConfirmDeleteIdea = useCallback(() => {
    dispatch(deleteFromIdeasAsync(currentIdea?.ticker_id))
      // @ts-ignore
      .then(() => {
        loadScreenFromIdeas();
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
    handleOnChangeVisibleDeleteIdeaModal();
  }, [currentIdea?.ticker_id, dispatch, handleOnChangeVisibleDeleteIdeaModal, loadScreenFromIdeas]);

  const handleOnDelete = useCallback((idea) => {
    setCurrentIdea(idea);
    setDeleteIdeaModalVisible((prev) => !prev);
  }, []);

  const loadPortfolio = useCallback(() => {
    dispatch(loadPortfoliosAsync(0, 100))
      // @ts-ignore
      .then((res) => {
        const { items } = res;
        setPortfolios(items);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  const handleChangeEvent = useCallback(
    (val, record) => {
      switch (val) {
        case 'add_to_portfolio':
          loadPortfolio();
          setSelectedTicker(record.ticker_id);
          setAddToPortfolioModalVisible((prev) => !prev);
          break;
        case 'delete_idea':
          handleOnDelete(record);
      }
    },
    [loadPortfolio, handleOnDelete]
  );

  const handleTickerAddToPortfolio = useCallback(
    (selectedPortfolio) => {
      if (selectedPortfolio) {
        dispatch(createPortfolioTickerAsync(selectedPortfolio.id, { tickerId: selectedTicker, sharesCount: 0 }))
          // @ts-ignore
          .then(() => {
            appMessage(typeMessage.SUCCESS, 'success_message_ticker_added');
          })
          .catch((e) => {
            appMessage(typeMessage.ERROR, e.message);
          });
      }

      setAddToPortfolioModalVisible((prev) => !prev);
    },
    [dispatch, selectedTicker]
  );

  useEffect(() => {
    if (tableData.length) {
      setInfoCol({
        sorted: false,
        filtered: false,
        width: 48,
        isVisible: true,
        fixed: 'right',
        render: (value, record) => {
          return <RowMenuScreenIdeas onClickElement={(val) => handleChangeEvent(val, record)} />;
        }
      });
    }
  }, [handleChangeEvent, handleOnDelete, tableData.length]);

  useEffect(() => {
    if (!isFirstLoading.current) {
      loadScreenFromIdeas();
    } else {
      isFirstLoading.current = false;
    }
  }, [loadScreenFromIdeas]);

  const handleChangePagination = useCallback((page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      index: page,
      limit: pageSize
    }));
  }, []);

  const handleSort = useCallback(
    (sortElem) => {
      let res = [];
      if (!sort.find((el) => el.column === sortElem.column)) {
        res = [...sort, sortElem];
      } else {
        res = sort.map((el) => {
          if (el.column === sortElem.column) {
            return { ...el, direction: sortElem.direction };
          }
          return el;
        });
      }
      setSort(res);
    },
    [sort]
  );

  const handleSortOrderEnd = useCallback(
    (oldIndex, newIndex) => {
      const newArray = [...sort];
      const el = newArray[newIndex];
      newArray[newIndex] = newArray[oldIndex];
      newArray[oldIndex] = el;
      setSort(newArray);
    },
    [sort]
  );

  const handleDeleteSort = useCallback(
    (sortElem) => {
      const res = sort.filter((el) => el.column !== sortElem.column);
      setSort(res);
    },
    [sort]
  );

  const handleSelectLimit = useCallback((limit) => {
    setPagination((prev) => ({ ...prev, index: 1, limit: limit }));
  }, []);

  const handleClickShowLines = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={`ideas ${styles.page}`}>
      {isSpinnerVisible && (
        <div className={styles.spinnerWrap}>
          <AppSpinner />
        </div>
      )}
      <h1 className={`page__title-uppercase ${styles.title}`}>{t('title_my_ideas')}</h1>
      <div className={styles.showLinesWrap}>
        <Dropdown
          overlay={<MenuRowsPerPage showLines={pagination.limit} onClick={handleSelectLimit} />}
          trigger={['click']}
        >
          <div data-test="ideas_page_show_lines" className={styles.showLines} onClick={(e) => handleClickShowLines(e)}>
            {t('table_show_lines')} <span>{pagination.limit}</span>
          </div>
        </Dropdown>
      </div>

      <AppDificultTable
        columns={columns.length ? [...columns, infoCol] : []}
        listOfData={tableData}
        dataKey={'tickerId'}
        sort={sort}
        onSort={handleSort}
        onSortOrderEnd={handleSortOrderEnd}
        onDeleteSort={handleDeleteSort}
        pagination={pagination}
        onChangePagination={handleChangePagination}
      />

      <DeleteModal
        visible={deleteIdeaModalVisible}
        onDelete={handleOnConfirmDeleteIdea}
        onCancel={handleOnChangeVisibleDeleteIdeaModal}
      />

      <AddToPortfolioModal
        visible={addToPortfolioModalVisible}
        onChangeVisible={handleTickerAddToPortfolio}
        portfolios={portfolios}
      />

      <RestrictionModal isVisible={isResctrictionModalVisible} text={t('restriction_limit_text')} isUnclosable />
    </div>
  );
};
