import React, { useMemo } from 'react';

import { Input } from 'antd';
import './styles.css';

const { TextArea } = Input;

/**
 * @param {object} props
 * @param {string} [props.placeholder]
 * @param {string} [props.value]
 * @param {string} [props.label]
 * @param {() => void} [props.onChange]
 */
export const AppTextarea = ({ placeholder, label, value, onChange, ...rest }) => {
  const componentId = useMemo(() => Date.now().toString(16), []);
  return (
    <div className="mg-input-textarea__wrap">
      <label htmlFor={componentId} className="mg-input-textarea__label">
        {label}
      </label>
      <TextArea
        id={componentId}
        value={value}
        className="mg-input-textarea"
        placeholder={placeholder}
        // type={type}
        autoSize={{ minRows: 2, maxRows: 6 }}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};
