import React, { useCallback, useEffect, useState } from 'react';

import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import styles from '../../../Edit.module.css';
import { MembersList } from '../../../members-list/index';
import { loadAccountsAsync } from 'src/app/api/admin';
import {
  loadNotificationInfoAsync,
  createNotificationAsync,
  updateNotificationAsync,
  deleteNotificaionAsync
} from 'src/app/api/polls-and-notifications';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { ReactComponent as BackArrow } from 'src/assets/svg/admin/back-arrow.svg';

export const NotificationEdit = () => {
  const [sendToAllInit, setSendToAllInit] = useState();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [notificationData, setNotificationData] = useState({});

  const { t } = useTranslation();
  const { notificationId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { TextArea } = Input;

  useEffect(() => {
    dispatch(loadAccountsAsync(0, 1000000))
      .then((data) => {
        setAllUsers(data?.items);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  useEffect(() => {
    if (notificationId) {
      dispatch(loadNotificationInfoAsync(notificationId))
        .then((response) => {
          const notification = response[0];
          if (notification) {
            setNotificationData(notification);
            setSelectedMembers(notification.toAllUsers ? [] : notification.receivers);
            setSendToAllInit(notification.toAllUsers);
          }
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    } else {
      setNotificationData((prev) => ({ ...prev, text: '' }));
    }
  }, [dispatch, notificationId]);

  useEffect(() => {
    setNotificationData((prev) => ({
      ...prev,
      receivers: selectedMembers?.map((email) => ({
        id: allUsers?.find((el) => el.email === email)?.id,
        email: email
      }))
    }));
  }, [allUsers, selectedMembers]);

  const handleSetRecipients = useCallback(
    (data) => {
      setNotificationData((prev) => ({
        ...prev,
        receivers: data.map((email) => ({
          id: allUsers?.find((el) => el.email === email)?.id,
          email: email
        }))
      }));
    },
    [allUsers]
  );
  const handleSetSendToAll = useCallback((data) => {
    setNotificationData((prev) => ({ ...prev, toAllUsers: data }));
  }, []);

  const handleDeleteNotification = useCallback(() => {
    dispatch(deleteNotificaionAsync(notificationData.id))
      .then(() => {
        navigate('/notifications-and-polls#notifications');
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, navigate, notificationData.id]);

  const handleSaveNotification = useCallback(() => {
    if (!notificationData.text) {
      appMessage(typeMessage.WARNING, 'enter_text');
      return;
    }

    if (!notificationData.toAllUsers && notificationData.receivers.length === 0) {
      appMessage(typeMessage.WARNING, 'choose_recipients');
      return;
    }

    const notification = {
      ...notificationData,
      members: notificationData?.receivers.map((el) => ({ id: el.id }))
    };
    if (notificationId) {
      dispatch(updateNotificationAsync(notification, notificationId))
        // @ts-ignore
        .then(() => {
          appMessage(typeMessage.SUCCESS, 'notification_edit');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    } else {
      dispatch(createNotificationAsync(notification))
        // @ts-ignore
        .then((data) => {
          appMessage(typeMessage.SUCCESS, 'notification_create');
          navigate('/notifications-and-polls#notifications');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [dispatch, navigate, notificationData, notificationId]);

  const handleChange = (e) => {
    setNotificationData((prev) => ({ ...prev, text: e.currentTarget.value }));
  };

  return (
    <div className="notifications-edit">
      <div className={styles.wrapHeader}>
        <div className={styles.header}>
          <Button
            onClick={() => {
              navigate('/notifications-and-polls#notifications');
            }}
            className={`mg-btn _text ${styles.backBtn}`}
          >
            <BackArrow /> {t('all_notifications_btn')}
          </Button>
          <div className={styles.totalMembers}>
            <span>
              {t('total_members')}: {notificationData?.toAllUsers ? allUsers?.length : selectedMembers?.length}
            </span>
            {notificationId && (
              <span className={styles.deleteText} onClick={handleDeleteNotification}>
                {t('delete_notification')}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <TextArea
            autoSize={{ minRows: 8 }}
            className="mg-input-textarea"
            value={notificationData?.text}
            onChange={handleChange}
          />
          <Button onClick={handleSaveNotification} className={`${styles.saveBtn} mg-btn _blue`}>
            {t('save')}
          </Button>
        </div>
        <MembersList
          setRecipients={handleSetRecipients}
          setSelectedMembers={setSelectedMembers}
          setSendToAll={handleSetSendToAll}
          selectedMembers={selectedMembers}
          sendToAllInit={sendToAllInit}
        />
      </div>
    </div>
  );
};
