import React, { useState, useCallback, useEffect } from 'react';

import { Divider, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { loadAlertTypesAsync, searchCompanyByNameAsync } from 'src/app/api/alerts';
import { ErrorHandling } from 'src/app/components/error-handler';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import {
  setAlerts,
  selectInsidersAlertsTotal,
  selectPriceAlertsTotal,
  selectInsidersAlerts,
  selectPriceAlerts
} from 'src/app/slices/alertsSlice';
import { selectProfile } from 'src/app/slices/profileSlice';
import { ReactComponent as AlertsIcon } from 'src/assets/svg/alerts-title-icon.svg';

import styles from './AccountAlerts.module.css';
import { AccountInsidersTab } from './components/account-insiders-tab/index';
import { AccountPriceTab } from './components/account-price-tab/index';
import { AlertsForm } from './components/alertsForm';
import { SearchResults } from './components/search-results/index';
import { SetAlert } from './components/set-alert-modal/index';
import './styles.css';

const { TabPane } = Tabs;
/**
 * @param {object} props
 * @param {(e) => void} [props.handleError]
 * @returns {JSX.Element}
 */
const AccountAlertsComponent = ({ handleError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const insidersAlertsTotal = useSelector(selectInsidersAlertsTotal);
  const priceAlertsTotal = useSelector(selectPriceAlertsTotal);
  const insiderAlerts = useSelector(selectInsidersAlerts);
  const priceAlerts = useSelector(selectPriceAlerts);
  const profile = useSelector(selectProfile);

  const [searchResults, setSearchResults] = useState([]);
  const [value, setValue] = useState(null);
  const [selectedResults, setSelectedResults] = useState([]);
  const [load, setLoad] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    dispatch(loadAlertTypesAsync())
      .then((data) => {
        dispatch(setAlerts(data));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  const loadAlerts = () => {
    setLoad((prev) => !prev);
    setVisible(false);
  };
  const handleSearch = useCallback(
    (value) => {
      dispatch(searchCompanyByNameAsync(value))
        .then((results) => {
          const resCompanies = results.map((elem) => {
            return {
              label: `${elem?.display_name}`,
              value: elem?.person ? elem.person_id : elem?.ticker_id ? elem?.ticker_id : elem?.cik,
              companyCik: elem.cik,
              isPerson: elem?.person !== undefined,
              isTicker: elem?.ticker_id !== undefined,
              tickerCompanyName: elem?.ticker_id ? elem?.company?.company_name : undefined
            };
          });
          setSearchResults([...resCompanies]);
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch]
  );

  const handleCleanSearch = useCallback(() => {
    setSelectedResults([]);
    setSearchResults([]);
    setValue({
      value: null
    });
  }, []);

  const handleSelectCompany = useCallback(
    (value, text) => {
      if (text) {
        const elem = searchResults.find((el) => el.value === value);
        const isPerson = elem.isPerson;
        const isTicker = elem.isTicker;
        setValue({
          // value: text,
          field: isPerson ? 'personName' : isTicker ? 'tickerName' : 'companyName'
        });
        setSelectedResults([elem]);
        setSearchResults([]);
      } else {
        handleCleanSearch();
      }
    },
    [handleCleanSearch, searchResults]
  );

  const getTotalAlerts = useCallback(() => {
    if (profile?.restriction?.data?.alertCreationLimit === null) {
      return Infinity;
    }
    return profile?.restriction?.data?.alertCreationLimit;
  }, [profile?.restriction?.data?.alertCreationLimit]);

  const handleShowModal = useCallback(
    (key, companyOrPersonNameOrTickerName, companyCik, tickerId) => {
      setModalData({
        key,
        companyName: companyOrPersonNameOrTickerName,
        companyCik,
        alertsNumber: getTotalAlerts() - insidersAlertsTotal - priceAlertsTotal
      });
      setVisible(true);
    },
    [getTotalAlerts, insidersAlertsTotal, priceAlertsTotal]
  );

  return (
    <div className={`${styles.page} pageAlerts`}>
      <div className={styles.paddings}>
        <h1 className={`${styles.title} page__title-uppercase`}>
          {t('alerts_title')}
          <AlertsIcon />
        </h1>
        <p className={styles.yellowText}>
          {' '}
          {t('set_alerts_text')} {getTotalAlerts() - insidersAlertsTotal - priceAlertsTotal}
        </p>
        <Divider className={styles.divider} />
        <AlertsForm
          value={value?.value}
          onSearch={handleSearch}
          result={searchResults}
          onSubmit={handleSelectCompany}
        />
        <SearchResults
          selectedTabKey={location.hash ? location.hash.replace('#', '') : '1'}
          onShowModal={(name, cik) => {
            handleShowModal(location.hash ? location.hash.replace('#', '') : '1', name, cik);
          }}
          selectedResults={selectedResults}
          onClean={handleCleanSearch}
          value={value?.value}
        />
      </div>

      <div className={`${styles.tabsWrap} tabs-alerts`}>
        <p className={selectedResults.length ? `${styles.yellowText} ${styles.tabsText}` : styles.tabsText}>
          {selectedResults.length ? t('my_alerts_set') : t('alerts_set') + ':'}{' '}
          <b>&nbsp;{insidersAlertsTotal + priceAlertsTotal}</b>
        </p>
        <Tabs
          defaultActiveKey={location.hash ? location.hash.replace('#', '') : '1'}
          onChange={(key) => {
            navigate(`${location.pathname}#${key}`);
          }}
          className={styles.tabsContainer}
        >
          <TabPane
            forceRender
            className={styles.tab}
            tab={
              selectedResults.length
                ? t('alerts_tab_position_title') + ' (' + insiderAlerts.length + ')'
                : t('alerts_tab_position_title')
            }
            key="1"
          >
            <AccountInsidersTab
              onShowModal={handleShowModal}
              queryTemplate={
                selectedResults.length
                  ? selectedResults[0].isTicker
                    ? selectedResults[0].tickerCompanyName
                    : value?.value
                  : null
              }
              queryField={selectedResults.length ? (selectedResults[0].isTicker ? 'companyName' : value?.field) : null}
              onLoad={load}
              handleError={handleError}
            ></AccountInsidersTab>
          </TabPane>
          <TabPane
            forceRender
            className={styles.tab}
            tab={
              selectedResults.length
                ? t('alerts_tab_price_title') + ' (' + priceAlerts.length + ')'
                : t('alerts_tab_price_title')
            }
            key="2"
          >
            <AccountPriceTab
              onShowModal={handleShowModal}
              queryTemplate={selectedResults.length ? value?.value : null}
              queryField={selectedResults.length ? value?.field : null}
              onLoad={load}
              handleError={handleError}
            ></AccountPriceTab>
          </TabPane>
        </Tabs>

        <Divider className={`${styles.divider} ${styles.dividerTabs}`} />
      </div>
      <SetAlert
        loadAlerts={loadAlerts}
        visible={visible}
        companyName={modalData?.companyName}
        companyCik={modalData?.companyCik}
        defaultActiveKey={modalData?.key}
        alertsNumber={modalData?.alertsNumber}
        handleError={handleError}
      />
    </div>
  );
};
export const AccountAlerts = () => {
  return (
    <ErrorHandling>
      <AccountAlertsComponent />
    </ErrorHandling>
  );
};
