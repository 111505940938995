import React, { useState } from 'react';

import { Collapse, Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowSvg } from 'src/assets/svg/arrowSort.svg';
import { ReactComponent as TitleSvg } from 'src/assets/svg/table/filter-title.svg';

import styles from './FilterStyles.module.css';
import './styles.css';

const { Panel } = Collapse;

/**
 * @param {object} props
 * @param {(count: number) => void} props.onClick
 * @param {number} props.showLines
 */
const MenuRowsPerPage = ({ onClick, showLines }) => {
  /** @type {(e: any, count: number) => void} */
  const clickItem = (e, count) => {
    e.domEvent.stopPropagation();
    onClick(count);
  };
  return (
    <Menu>
      <Menu.Item
        data-test="custom_screen_page_selected_10_lines"
        className={`${styles.rowsMenuItem} ${showLines === 10 ? styles.selectedItem : ''}`}
        key="0"
        onClick={(e) => clickItem(e, 10)}
      >
        10
      </Menu.Item>
      <Menu.Item
        data-test="custom_screen_page_selected_30_lines"
        className={`${styles.rowsMenuItem} ${showLines === 30 ? styles.selectedItem : ''}`}
        key="1"
        onClick={(e) => clickItem(e, 30)}
      >
        30
      </Menu.Item>
      <Menu.Item
        data-test="custom_screen_page_selected_50_lines"
        className={`${styles.rowsMenuItem} ${showLines === 50 ? styles.selectedItem : ''}`}
        key="2"
        onClick={(e) => clickItem(e, 50)}
      >
        50
      </Menu.Item>
    </Menu>
  );
};
/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {number} props.showLines
 * @param {(count: number) => void} props.onClick
 */
const CollapseHeader = ({ open, showLines, onClick }) => {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div className={`${styles.collapseHeader}`}>
      <div className={`${styles.title} ${open ? styles.openTitle : ''}`}>
        <TitleSvg />
        {t('table_filter_title')}
        <ArrowSvg />
      </div>
      <div className={styles.showLines}>
        <Dropdown overlay={<MenuRowsPerPage showLines={showLines} onClick={onClick} />} trigger={['click']}>
          <div data-test="custom_screen_page_show_lines" className={styles.showLines} onClick={(e) => handleClick(e)}>
            {t('table_show_lines')} <span>{showLines}</span>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
/**
 * @param {object} props
 * @param {number} props.showLines
 * @param {(count: number) => void} props.onClick
 * @param {object | object[]} props.children
 * @param {boolean} [props.isDefault]
 */
export const AppTableFilter = ({ showLines, onClick, children, isDefault }) => {
  const [open, setOpen] = useState(isDefault === false);
  const handleChange = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.collapseWrap}>
      <Collapse defaultActiveKey={[isDefault === false ? '1' : null]} ghost onChange={(data) => handleChange()}>
        <Panel
          className="collapse"
          showArrow={false}
          header={<CollapseHeader open={open} showLines={showLines} onClick={onClick} />}
          key="1"
        >
          <div className={styles.childrenWrap}>{children}</div>
        </Panel>
      </Collapse>
    </div>
  );
};
