import React, { useState, useEffect } from 'react';

import { Modal, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AppRadio } from 'src/app/components/form/radio';
import { AppSpinner } from 'src/app/components/spinner/spinner-component';

import styles from './AddToPortfolioModal.module.css';

export const AddToPortfolioModal = ({ visible, onChangeVisible, portfolios }) => {
  const { t } = useTranslation();

  const [listPortfolios, setListPortfolios] = useState(portfolios);
  const [addDisabledButton, setAddDisabledButton] = useState(true);

  useEffect(() => {
    setAddDisabledButton(true);
    setListPortfolios(portfolios);
  }, [portfolios]);

  const handleOk = () => {
    setListPortfolios([]);
    onChangeVisible(listPortfolios.find((el) => el.isSelected));
  };

  const handleChange = (value, checked) => {
    const result = listPortfolios.map((el) => {
      if (el.id === value) {
        return { ...el, isSelected: checked };
      } else {
        return { ...el, isSelected: false };
      }
    });
    setListPortfolios(result);
    setAddDisabledButton(!result.find((el) => el.isSelected));
  };

  return (
    <Modal
      title={''}
      centered
      width={800}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('add')}
      cancelText={t('cancel')}
      getContainer={'main'}
      data-test={'add_to_portfolio_modal'}
      okButtonProps={{
        disabled: addDisabledButton,
        className: 'mg-btn _green'
      }}
      cancelButtonProps={{ className: 'mg-btn _green-border' }}
      onOk={handleOk}
      onCancel={() => {
        onChangeVisible(null);
      }}
    >
      <p className={styles.titleModal}>{t('select_portfolio')}</p>
      {!listPortfolios && (
        <div className={styles.spinnerWrap}>
          <AppSpinner />
        </div>
      )}
      {listPortfolios && listPortfolios.length ? (
        <PerfectScrollbar options={{ wheelPropagation: false }} className={'scroll-search-column'}>
          <div className={styles.portfolioWrap}>
            <Radio.Group
              onChange={(e) => handleChange(e.target.value, e.target.checked)}
              value={listPortfolios.find((el) => el.isSelected)?.id}
            >
              {listPortfolios.map((port) => {
                return (
                  <div
                    key={port.id}
                    className={styles.portfolio}
                    onClick={() => handleChange(port.id, !port.isSelected)}
                  >
                    <span>{port.name}</span>
                    <AppRadio value={port.id} checked={port.isSelected} />
                  </div>
                );
              })}
            </Radio.Group>
          </div>
        </PerfectScrollbar>
      ) : (
        listPortfolios && <div style={{ paddingTop: '10px' }}>{t('no_portfolio_label')}</div>
      )}
    </Modal>
  );
};
