import React, { useEffect } from 'react';

import { Button, Form, Grid } from 'antd';
import { useTranslation } from 'react-i18next';

import { AppInputPassword } from 'src/app/components/form/input-password';

import styles from './ChangePasswordForm.module.css';

const { useBreakpoint } = Grid;
export const ChangePasswordForm = ({ email, onSubmit }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const changePasswordInitialValues = {
    newPassword: '',
    confirmPassword: ''
  };

  useEffect(() => {
    form.setFields([{ name: 'email', value: email }]);
  }, [email, form]);

  return (
    <Form
      name="changePassword"
      form={form}
      initialValues={changePasswordInitialValues}
      onFinish={onSubmit}
      autoComplete="off"
      className={styles.form}
      style={screens.xs ? { marginRight: 0 } : {}}
    >
      <Form.Item
        className={styles.formItem}
        name="oldPassword"
        rules={[
          {
            required: true,
            message: t('required_input')
          },
          () => ({
            validator(_, value) {
              const reg = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
              if (!value || reg.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('password_incorrect')));
            }
          })
        ]}
      >
        <AppInputPassword
          data-test="change_password_page_new_password"
          placeholder={t('old_password')}
          autoComplete={'off'}
        />
      </Form.Item>

      <Form.Item
        className={styles.formItem}
        name="newPassword"
        rules={[
          {
            required: true,
            message: t('required_input')
          },
          () => ({
            validator(_, value) {
              const reg = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
              if (!value || reg.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('password_incorrect')));
            }
          })
        ]}
      >
        <AppInputPassword
          data-test="change_password_page_new_password"
          placeholder={t('new_password')}
          autoComplete={'off'}
        />
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: t('required_input')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('confirm_password_error')));
            }
          })
        ]}
      >
        <AppInputPassword
          data-test="change_password_page_confirm_password"
          placeholder={t('confirm_password')}
          autoComplete={'off'}
        />
      </Form.Item>
      <Form.Item shouldUpdate className={styles.formItem}>
        {() => (
          <Button
            data-test="change_password_page_save"
            htmlType="submit"
            className="mg-btn _green"
            style={{ maxWidth: '265px', minHeight: '36px' }}
            disabled={
              !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
            }
          >
            {t('btn_save_new_password')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
