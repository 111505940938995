import React, { useRef } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { ReactComponent as CloseSvg } from 'src/assets/svg/close.svg';
import { ReactComponent as SvgArrow } from 'src/assets/svg/table/scroll-arrow.svg';

import styles from './SortOrder.module.css';

const SortableItem = SortableElement(({ sort, onDeleteSort }) => (
  <div className={styles.sortItem}>
    {sort.sortTitle}
    <Button
      data-test="custom_screen_page_sort_order_close_item"
      className={`mg-btn _text ${styles.buttonClose}`}
      onClick={() => onDeleteSort(sort)}
    >
      <CloseSvg />
    </Button>
  </div>
));

export const TableSortOrder = ({ sort, onDeleteSort, isVisibleScroll, onSortEnd }) => {
  const scrollbarsRef = useRef();
  const { t } = useTranslation();
  const handleClickScroll = (direction) => {
    if (direction === 'right') {
      scrollbarsRef.current.scrollTo({
        top: 0,
        left: scrollbarsRef.current.scrollLeft + 100,
        behavior: 'smooth'
      });
    } else {
      scrollbarsRef.current.scrollTo({
        top: 0,
        left: scrollbarsRef.current.scrollLeft - 100,
        behavior: 'smooth'
      });
    }
  };
  const SortableList = SortableContainer(({ sort, onDeleteSort }) => {
    return (
      <PerfectScrollbar className={'sort-scrollbar'} containerRef={(el) => (scrollbarsRef.current = el)}>
        <div className={styles.carousel}>
          {sort.map((sort, index) => (
            <SortableItem index={index} key={sort.column} sort={sort} onDeleteSort={onDeleteSort} />
          ))}
        </div>
      </PerfectScrollbar>
    );
  });

  const handleSortOver = (data) => {
    const { newIndex, oldIndex } = data;
    onSortEnd(oldIndex, newIndex);
  };

  const shouldCancelStart = (e) => {
    if (['button', 'svg', 'path'].indexOf(e.target.tagName.toLowerCase()) !== -1 || sort.length === 1) {
      return true;
    }
  };
  return (
    <div className={`${styles.orderWrap} ${!isVisibleScroll ? styles.scrollVisible : ''}`}>
      <span className={styles.orderTitle}>{t('order_title')}</span>
      <div className={styles.sortList}>
        <Button
          data-test="custom_screen_page_sort_order_scroll_left"
          className={`mg-btn _text ${styles.buttonCarusel} ${styles.buttonCaruselLeft}`}
          onClick={() => handleClickScroll('left')}
        >
          <SvgArrow />
        </Button>{' '}
        <SortableList
          shouldCancelStart={shouldCancelStart}
          axis={'x'}
          onDeleteSort={onDeleteSort}
          sort={sort}
          onSortEnd={(data) => handleSortOver(data)}
        />
        <Button
          data-test="custom_screen_page_sort_order_scroll_right"
          className={`mg-btn _text ${styles.buttonCarusel}`}
          onClick={() => handleClickScroll('right')}
        >
          <SvgArrow />
        </Button>
      </div>
    </div>
  );
};
