import React, { useCallback, useEffect } from 'react';

import { Button } from 'antd';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import AppInput from 'src/app/components/form/input';
import { AppInputNumber } from 'src/app/components/form/input-number';
import { AppSelect } from 'src/app/components/form/select';
import { ReactComponent as ShowIcon } from 'src/assets/svg/custom-screen/show-eye.svg';
import { ReactComponent as TrashIcon } from 'src/assets/svg/custom-screen/trash.svg';
import { ReactComponent as AddFilter } from 'src/assets/svg/table/filter-add.svg';

import { unitOptions } from './consts';
import styles from './FilterTableRow.module.css';
import { getUnitFromRangeInputNumber } from './helpers';

const filterTypes = {
  input: 'input',
  range: 'range',
  select: 'select',
  checkbox: 'checkbox',
  multiSelect: 'multiple_select'
};

/**
 * @param {object} param0
 * @param {((val: string) => void)} param0.onOpenModal
 * @param {ColumnTypes.Filter[]} param0.filter
 * @param {ColumnTypes.Column} param0.col
 * @param {ScreenFuncs.HandleChangeFilter} param0.onChange
 * @param {() => void} param0.onDeleteFilter
 * @param {(col: ColumnTypes.Column) => void} param0.onChangeVisibleNestedRow
 * @param {any} param0.scrollRef
 * @param {number} param0.scrollValue
 */
const FilterTableRowComponent = ({
  onOpenModal,
  filter,
  col,
  onChange,
  onDeleteFilter,
  onChangeVisibleNestedRow,
  scrollRef,
  scrollValue
}) => {
  const { t } = useTranslation();

  const getFilter = useCallback(
    /**
     * @param {ColumnTypes.FilterInput[]} filter
     * @param {ColumnTypes.FilterInput} selectedFilter
     * @param {ScreenFuncs.HandleChangeFilter} onChange
     */
    (filter, selectedFilter, onChange) => {
      let updatedSelectedFilter = selectedFilter;

      /** @type {(rangeIdx: number, val: string) => void} */
      const onRangeInput = (rangeIdx, val) => {
        // @ts-ignore
        const inputValue = isNaN(val) ? null : parseFloat(val);
        if (!rangeIdx) {
          updatedSelectedFilter = onChange(
            filter[0].columnName,
            updatedSelectedFilter?.value ? [inputValue, parseFloat(updatedSelectedFilter.value[1])] : [inputValue, null]
          );
        } else {
          updatedSelectedFilter = onChange(
            filter[0].columnName,
            updatedSelectedFilter?.value ? [parseFloat(updatedSelectedFilter.value[0]), inputValue] : [null, inputValue]
          );
        }
      };

      switch (filter[0]?.format) {
        case filterTypes.input:
          return (
            <AppInput
              className={styles.textInput}
              // @ts-ignore
              value={selectedFilter?.value || ''}
              onBlur={(val) => onChange(filter[0].columnName, val)}
              disabled={!selectedFilter?.name}
            ></AppInput>
          );
        case filterTypes.range:
          return (
            <div className={`${styles.rangeWrap} ${filter[0].error ? styles.rangeError : ''}`}>
              <div className={styles.rangeInput}>
                <span>
                  <Trans i18nKey="from_range"></Trans>
                </span>
                <div className={styles.rangeInputNumber}>
                  <AppInputNumber
                    value={selectedFilter?.value ? selectedFilter?.value[0] : ''}
                    onBlur={(val) => onRangeInput(0, val)}
                    onPressEnter={(val) => onRangeInput(0, val)}
                    min={null}
                  ></AppInputNumber>
                </div>
                <AppSelect
                  value={
                    selectedFilter?.unit
                      ? selectedFilter?.unit[0]
                      : selectedFilter?.value
                      ? getUnitFromRangeInputNumber(parseFloat(selectedFilter?.value[0]))
                      : null
                  }
                  placeholder="Unit"
                  options={unitOptions}
                  onChange={(val) => {
                    updatedSelectedFilter = onChange(
                      filter[0].columnName,
                      undefined,
                      selectedFilter?.unit ? [val, selectedFilter.unit[1]] : [val, null],
                      true
                    );
                  }}
                />
              </div>

              <div className={styles.rangeInput}>
                <span>
                  <Trans i18nKey="to_range"></Trans>
                </span>
                <div className={styles.rangeInputNumber}>
                  <AppInputNumber
                    value={selectedFilter?.value ? selectedFilter?.value[1] : ''}
                    onBlur={(val) => onRangeInput(1, val)}
                    onPressEnter={(val) => onRangeInput(1, val)}
                    min={null}
                  ></AppInputNumber>
                </div>
                <AppSelect
                  value={
                    selectedFilter?.unit
                      ? selectedFilter?.unit[1]
                      : selectedFilter?.value
                      ? getUnitFromRangeInputNumber(parseFloat(selectedFilter?.value[1]))
                      : null
                  }
                  placeholder="Unit"
                  options={unitOptions}
                  onChange={(val) => {
                    updatedSelectedFilter = onChange(
                      filter[0].columnName,
                      undefined,
                      selectedFilter?.unit ? [selectedFilter.unit[0], val] : [null, val],
                      true
                    );
                  }}
                />
              </div>
              {filter[0].error ? <span className={styles.filterError}>{filter[0].error}</span> : ''}
            </div>
          );
        case filterTypes.select: {
          const opt = selectedFilter?.options?.map((el) => ({ label: el.title, value: el.value }));
          return (
            <AppSelect
              options={opt}
              value={selectedFilter?.value || null}
              onChange={(val) => {
                onChange(filter[0].columnName, val, undefined, true);
              }}
            ></AppSelect>
          );
        }
        case filterTypes.multiSelect: {
          // @ts-ignore
          const opt = selectedFilter?.value ? selectedFilter?.value.map((el) => el).join(', ') : '';
          return (
            <div onClick={onOpenModal}>
              <AppInput className={styles.selectedIndustryLabel} value={opt}></AppInput>
            </div>
          );
        }
      }
    },
    [onOpenModal]
  );

  const getChooseFilterType = useCallback(
    (col, filter) => {
      const opt = Object.values(filter)?.map((filt) => ({ label: filt.name, value: filt.name }));
      return (
        <AppSelect
          options={opt}
          value={col.selectedFilter?.name || null}
          onChange={(val) => onOpenModal(val)}
        ></AppSelect>
      );
    },
    [onOpenModal]
  );

  useEffect(() => {
    scrollRef.current.scrollTop = scrollValue;
  }, [scrollRef, scrollValue]);

  const handleOnClickVisibleIcon = useCallback(() => {
    scrollRef.current.scrollTop = scrollValue;
    onChangeVisibleNestedRow(col);
  }, [col, onChangeVisibleNestedRow, scrollRef, scrollValue]);

  const handleOnDeleteFilter = useCallback(() => {
    scrollRef.current.scrollTop = scrollValue;
    onDeleteFilter();
  }, [onDeleteFilter, scrollRef, scrollValue]);

  return (
    <div className={styles.filterRow}>
      {filter[0]?.columnName === 'industry' && (
        <Button data-test="custom_screen_page_add_filter" className={`mg-btn _text ${styles.addFilterButton}`}>
          <AddFilter onClick={onOpenModal} />
        </Button>
      )}
      {Object.entries(filter).length && (
        <div className={`${styles.filterWrap} ${col?.isGroupItem ? styles.filterWrapGroupItem : {}}`}>
          {col.otherColumns && col.type === 'Real' && <span className={styles.filterTitle}>{col.type + ':'}</span>}
          {Object.entries(filter).length > 1 && getChooseFilterType(col, filter)}
          <div className={styles.inputWrap}>{getFilter(filter, col.selectedFilter, onChange)}</div>
        </div>
      )}
      {col.otherColumns &&
        col.otherColumns.map((other) => {
          return (
            other.filter.length !== 0 && (
              <div
                className={`${styles.filterWrap} ${col?.isGroupItem ? styles.filterWrapGroupItem : {}}`}
                key={other.dataIndex}
              >
                {other.type === 'Quality' && (
                  <span className={(styles.filterTitle, styles.filterWrapGroupItem)}>{t('quality_filter_label')}</span>
                )}
                {/* <span className={styles.filterTitle}>{other.filter?.name ? other.filter?.name + ':' : ''}</span> */}
                <div className={styles.inputWrap}>{getFilter(other.filter, other.selectedFilter, onChange)}</div>
              </div>
            )
          );
        })}

      <Button
        data-test="custom_screen_page_add_filter"
        className={`mg-btn _text ${styles.removeFilterButton} ${
          col?.isGroupItem ? styles.removeFilterButtonGroupItem : {}
        }`}
      >
        <TrashIcon onClick={handleOnDeleteFilter} />
      </Button>

      {col?.isGroupItem && (
        <Button
          onClick={handleOnClickVisibleIcon}
          data-test="custom_screen_page_hide_nested_row"
          className={`mg-btn _text ${styles.removeFilterButton}`}
        >
          <ShowIcon className={classNames('actionIcon', { selected: col.isVisible })} />
        </Button>
      )}
    </div>
  );
};

function settingsPropsAreEqual(prevTab, nextTab) {
  return JSON.stringify(prevTab.col) === JSON.stringify(nextTab.col);
}

export const FilterTableRow = React.memo(FilterTableRowComponent, settingsPropsAreEqual);
