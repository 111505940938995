import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router';

import { joinToGroupAsync } from 'src/app/api/admin';
import { getSentInvitationsAsync, deleteProfileAsync } from 'src/app/api/profile';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { signout } from 'src/app/slices/authSlice';
import { selectProfile, logout } from 'src/app/slices/profileSlice';
import titleIcon from 'src/assets/svg/profile.svg';

import styles from './Account.module.css';
import { AccountInvitations } from './components/accountInvitations';
import { AccountSubscriptions } from './components/accountSubscriptions';
import { AdditionalLinks } from './components/additionalLinks';
import { AlertLimit } from './components/alertLimit';

export const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const { hash } = useParams();
  const [totalSentInvitations, setTotalSentInvitations] = useState(0);
  const [type, setType] = useState('');

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    setType(params.get('type') || '');
  }, []);

  useEffect(() => {
    if (type === 'delete-account') {
      dispatch(signout());
      dispatch(deleteProfileAsync(hash))
        .then(() => {
          dispatch(logout());
          navigate('/');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    } else if (type === 'invite-to-group') {
      dispatch(joinToGroupAsync(hash))
        .then(() => {
          appMessage(typeMessage.SUCCESS, 'added_to_group_success_message');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [dispatch, hash, navigate, type]);

  useEffect(() => {
    dispatch(getSentInvitationsAsync())
      .then((data) => {
        setTotalSentInvitations(data.total);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  return profile ? (
    <div className="page">
      <div className={styles.title}>
        <h1 className="page__title-uppercase" style={{ color: 'var(--title-text-blue)' }}>
          {t('title_profile')}
        </h1>
        <img src={titleIcon} className={styles.logo} />
      </div>
      <AccountSubscriptions />
      {profile?.customer?.subscription ? <AlertLimit profile={profile} /> : ''}
      <AccountInvitations profile={profile} totalSentInvitations={totalSentInvitations} />
      <AdditionalLinks />
    </div>
  ) : (
    ''
  );
};
