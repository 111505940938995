import React from 'react';

import { Button } from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { ReactComponent as DeleteSvg } from 'src/assets/svg/custom-screen/trash.svg';

import styles from './SavedFiltersModal.module.css';

export const FiltersList = ({ filters }) => {
  return (
    <PerfectScrollbar options={{ wheelPropagation: false }} className={'scroll-search-column'}>
      <div className={styles.filtersWrap}>
        {filters?.map((elem, index) => {
          return (
            <div key={elem.id} className={styles.filterItem}>
              {elem.title}{' '}
              <Button data-test="custom_screen_page_delete_filter" className={`mg-btn _text ${styles.deleteButton}`}>
                {' '}
                <DeleteSvg />{' '}
              </Button>
            </div>
          );
        })}
      </div>
    </PerfectScrollbar>
  );
};
