import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from '../../Resources.module.css';

export const MenuItem = ({ item, selectedMenuParams, scrollToAnchor }) => {
  const isSelected = useMemo(() => {
    if (!selectedMenuParams) {
      return;
    }

    return (
      selectedMenuParams[0] === item.articleId &&
      selectedMenuParams[1] === (item.anchor ? `#${item.anchor}` : item.anchor)
    );
  }, [item, selectedMenuParams]);

  const handleControl = useCallback(
    (e) => {
      if (isSelected) {
        e.preventDefault();
        scrollToAnchor(item.anchor);
      }
    },
    [isSelected, item.anchor, scrollToAnchor]
  );

  return (
    <li className={classNames(styles.menuItem, { [styles.selectedMenuItem]: isSelected })}>
      <Link onClick={handleControl} to={`/${item.link}`}>
        {item?.title}
      </Link>
      {item?.articles && (
        <ul>
          {item.articles.map((article, i) => (
            <MenuItem key={i} item={article} selectedMenuParams={selectedMenuParams} scrollToAnchor={scrollToAnchor} />
          ))}
        </ul>
      )}
    </li>
  );
};
