import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';

import styles from '../Header.module.css';
import { RestrictionModal } from 'src/app/components/restriction-modal';

export const HoverMenu = ({ visibleMenuId, visibleMenu, visibleMenuItems, onClickItem }) => {
  const { t } = useTranslation();

  const [isResctrictionModalVisible, setIsResctrictionModalVisible] = useState(false);

  return (
    <>
      <div
        className={
          visibleMenu && visibleMenuItems && visibleMenuItems.length
            ? styles.hoverMenu + ' ' + styles.hoverMenuVisible
            : styles.hoverMenu
        }
      >
        <PerfectScrollbar className={'scroll-header'}>
          <ul className={classNames(styles.subMenu, { [styles.subMenuProfile]: visibleMenuId === 'profile' })}>
            {visibleMenuItems.map((item) => {
              return (
                <li key={item.titleKey} className={styles.subMenuItemLi}>
                  {item.titleKey && (
                    <span className={styles.subMenuTitle} style={{ color: item.color }}>
                      {item.title || t(item.titleKey)}
                    </span>
                  )}
                  <div
                    className={
                      item.items.filter((el) => el !== null).length > 4 ? styles.linksWrapGrid : styles.linksWrap
                    }
                  >
                    {item.items
                      .filter((el) => el !== null)
                      .map((elem) => {
                        if (elem.link) {
                          return (
                            <Link
                              data-test={`header_${elem.titleKey}`}
                              key={elem.link}
                              to={elem.link}
                              onClick={(e) => onClickItem()}
                              className={styles.headerLink}
                            >
                              <span style={{ color: elem.color }}>{elem.title || t(elem.titleKey)}</span>
                            </Link>
                          );
                        } else if (elem.click) {
                          return (
                            <a
                              data-test={`${elem.title || elem.titleKey}`}
                              key={elem.title || elem.titleKey}
                              onClick={(e) => {
                                e.preventDefault();
                                elem.click();
                              }}
                              className={elem.titleKey === 'logout' ? styles.logoutLink : ''}
                            >
                              {elem.title || t(elem.titleKey)}
                            </a>
                          );
                        } else if (elem.component) {
                          return (
                            <div key={elem.key}>
                              <elem.component {...elem.propsMethods} />
                            </div>
                          );
                        } else {
                          return (
                            <span
                              data-test={`header_${elem.title || elem.titleKey}`}
                              className={styles.menuText}
                              key={elem.title || elem.titleKey}
                            >
                              {elem.title || t(elem.titleKey)}
                            </span>
                          );
                        }
                      })}
                  </div>
                </li>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
      <RestrictionModal
        isVisible={isResctrictionModalVisible}
        onCancel={() => setIsResctrictionModalVisible(false)}
        text={t('restriction_alert_text')}
      />
    </>
  );
};
