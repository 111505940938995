import React from 'react';

import { message } from 'antd';
import { t } from 'i18next';

import { formatString } from 'src/app/helpers/strings';
import { Store } from 'src/app/store';

import { DefineSvg } from './functions';

import './style.css';

export const UNAUTHORIZED = 401;

/** @type {(type: string, text: string | JSX.Element, data?: Errors.ErrorData, duration?: number,) => import('antd/lib/message').MessageType} */
export const appMessage = (type, text, data = {}, duration = 3) => {
  const statusCode = Store.getState().auth.code;
  if (statusCode === UNAUTHORIZED) {
    return;
  }
  if (typeof text === 'string') {
    text = t(text);
  }
  if (data && typeof text === 'string') {
    formatString(text, data);
  }
  return message.success({
    icon: <DefineSvg type={type} />,
    content: text,
    style: {
      marginTop: '80px'
    },
    duration: duration
  });
};
