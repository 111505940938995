import React, { useState, useCallback, useEffect } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import styles from '../../../Edit.module.css';
import { MembersList } from '../../../members-list';
import { Question } from '../question';
import { loadAccountsAsync } from 'src/app/api/admin';
import {
  loadPollInfoAsync,
  updatePollAsync,
  createPollAsync,
  deletePollAsync
} from 'src/app/api/polls-and-notifications';
import AppInput from 'src/app/components/form/input';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { ReactComponent as BackArrow } from 'src/assets/svg/admin/back-arrow.svg';

export const PollEdit = () => {
  const [sendToAllInit, setSendToAllInit] = useState(true);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  /** @type {UseHelper.UseStateType<NotificationTypes.PollData>} */
  const [pollData, setPollData] = useState({});

  const { t } = useTranslation();
  const { pollId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAccountsAsync(0, 1000000))
      // @ts-ignore
      .then((data) => {
        setAllUsers(data?.items);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch]);

  useEffect(() => {
    if (pollId) {
      dispatch(loadPollInfoAsync(pollId))
        // @ts-ignore
        .then((response) => {
          const poll = response[0];
          setPollData(poll);
          setSelectedMembers(poll.toAllUsers ? [] : poll.receivers);
          setSendToAllInit(poll.toAllUsers);
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    } else {
      setPollData((prev) => ({ ...prev, data: [{ title: '', answers: [] }] }));
    }
  }, [allUsers, dispatch, pollId]);

  useEffect(() => {
    if (selectedMembers && allUsers) {
      setPollData((prev) => {
        return {
          ...prev,
          receivers: selectedMembers.map((email) => ({
            id: allUsers?.find((el) => el.email === email)?.id,
            email: email
          }))
        };
      });
    }
  }, [allUsers, selectedMembers]);

  const handleSetRecipients = useCallback(
    (data) => {
      setPollData((prev) => ({
        ...prev,
        receivers: data.map((email) => ({
          id: allUsers?.find((el) => el.email === email)?.id,
          email: email
        }))
      }));
    },
    [allUsers]
  );

  const handleSetPollName = useCallback((data) => {
    setPollData((prev) => ({ ...prev, title: data }));
  }, []);

  const handleSetSendToAll = useCallback((data) => {
    setPollData((prev) => ({ ...prev, toAllUsers: data }));
  }, []);

  const handleSavePoll = useCallback(() => {
    if (!pollData.title) {
      appMessage(typeMessage.WARNING, 'enter_poll_title_warning');
      return;
    }

    if (!pollData.toAllUsers && pollData.receivers.length === 0) {
      appMessage(typeMessage.WARNING, 'choose_recipients');
      return;
    }

    const poll = {
      ...pollData,
      members: pollData.receivers.map((el) => ({ id: el.id }))
    };
    if (pollId) {
      dispatch(updatePollAsync(poll, pollId))
        // @ts-ignore
        .then(() => {
          appMessage(typeMessage.SUCCESS, 'poll_edit');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    } else {
      dispatch(createPollAsync(poll))
        // @ts-ignore
        .then((data) => {
          appMessage(typeMessage.SUCCESS, 'poll_create');
          navigate('/notifications-and-polls#polls');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [dispatch, navigate, pollData, pollId]);

  const handleSetData = useCallback((data) => {
    setPollData((prev) => ({ ...prev, data: data }));
  }, []);

  const handleChangeQuestions = useCallback(
    (question) => {
      const newQuestions = [...pollData.data];
      const questionIndex = pollData.data.findIndex((el) => el.id === question.id);
      newQuestions.splice(questionIndex, 1, question);
      handleSetData(newQuestions);
    },
    [handleSetData, pollData.data]
  );

  const handleDeleteQuestion = useCallback(
    (id) => {
      const newQuestions = [...pollData.data];
      const indexOfElement = newQuestions.findIndex((el) => el.id === id);
      newQuestions.splice(indexOfElement, 1);
      handleSetData(newQuestions);
    },
    [handleSetData, pollData.data]
  );

  const handleAddQuestion = useCallback(() => {
    const newQuestions = [...pollData.data, { title: '', answers: [], id: new Date().getTime() }];
    handleSetData(newQuestions);
  }, [handleSetData, pollData.data]);

  const handleDeletePoll = useCallback(() => {
    dispatch(deletePollAsync(pollData.id))
      // @ts-ignore
      .then(() => {
        navigate('/notifications-and-polls#polls');
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, navigate, pollData.id]);

  return (
    <div className="notifications-edit">
      <div className={styles.wrapHeader}>
        <div className={styles.header}>
          <Button
            onClick={() => {
              navigate('/notifications-and-polls#polls');
            }}
            className={`mg-btn _text ${styles.backBtn}`}
          >
            <BackArrow /> {t('all_polls_btn')}
          </Button>
          <AppInput
            value={pollData.title}
            onChange={handleSetPollName}
            className={styles.pollNameInput}
            placeholder={t('poll_title')}
            maxLength={1023}
          />

          <div className={styles.totalMembers}>
            <span>
              {t('total_members')}: {pollData?.toAllUsers ? allUsers.length : selectedMembers.length}
            </span>
            {pollId && (
              <span className={styles.deleteText} onClick={handleDeletePoll}>
                {t('delete_poll')}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          {pollData.data?.map((question) => (
            <Question
              question={question}
              setQuestion={handleChangeQuestions}
              handleDeleteQuestion={handleDeleteQuestion}
              key={question.id}
            />
          ))}
          <Button onClick={handleAddQuestion} className={styles.addQuestionBtn}>
            {t('add_question')}
          </Button>
          <Button onClick={handleSavePoll} className={`${styles.saveBtn} mg-btn _blue`}>
            {t('save')}
          </Button>
        </div>

        <MembersList
          setRecipients={handleSetRecipients}
          setSelectedMembers={setSelectedMembers}
          setSendToAll={handleSetSendToAll}
          selectedMembers={selectedMembers}
          sendToAllInit={sendToAllInit}
        />
      </div>
    </div>
  );
};
