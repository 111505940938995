import React, { useEffect, useMemo, useState } from 'react';

import { InputNumber } from 'antd';
import './styles.css';

/**
 * @param {object} props
 * @param {string} [props.placeholder]
 * @param {string} [props.label]
 * @param {number} props.min
 * @param {number} [props.max]
 * @param {number} [props.step]
 * @param {string} props.value
 * @param {(value: string)=> void} [props.onChange]
 * @param {(value: string) => void} props.onBlur
 * @param {(value: string) => void} props.onPressEnter
 * @param {boolean} [props.isInvalid]
 */
export const AppInputNumber = ({
  placeholder,
  label,
  min = 0,
  max = Number.MAX_VALUE,
  step = 1,
  value,
  onChange,
  onBlur,
  onPressEnter,
  isInvalid = false,
  ...rest
}) => {
  const componentId = useMemo(() => Date.now().toString(16), []);
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    } else {
      setVal(value);
    }
  };

  return (
    <div className="mg-input__wrap">
      <label htmlFor={componentId} className="mg-input__label">
        {label}
      </label>
      <InputNumber
        id={componentId}
        value={val}
        step={step}
        min={min}
        max={max}
        className={`mg-input-number ${isInvalid && 'invalid'}`}
        placeholder={placeholder}
        onChange={handleChange}
        onPressEnter={onPressEnter ? (e) => onPressEnter(e.target.value) : null}
        onBlur={onBlur ? (e) => onBlur(e.target.value) : null}
        {...rest}
      />
    </div>
  );
};
