import React, { useState } from 'react';

import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { sendNotificationDeleteAccountAsync } from 'src/app/api/profile';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import styles from './AdditionalLinks.module.css';

export const AdditionalLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const sendVerificationLink = () => {
    dispatch(sendNotificationDeleteAccountAsync())
      // @ts-ignore
      .then(() => {
        appMessage(typeMessage.SUCCESS, 'link_delete_profile_success_message');
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    sendVerificationLink();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={styles.additionalLinks}>
      <div className={styles.title}>
        <h3 className="page__subtitle">{t('account_additional_links_subtitle')}</h3>
        <hr />
      </div>
      <p className={styles.text}>{t('account_additional_links_text')}</p>
      <Button
        data-test="profile_page_delete_profile"
        onClick={showModal}
        className={'mg-btn _blue-border '}
        style={{ width: '273px', marginTop: '16px' }}
      >
        {t('btn_delete_account')}
      </Button>
      <Modal
        title={t('modal_title_warning')}
        centered
        visible={isModalVisible}
        forceRender
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('delete')}
        cancelText={t('cancel')}
        className="mg-modal _confirm"
        okButtonProps={{ className: 'mg-btn _green-border', 'data-test': 'profile_page_modal_delete' }}
        cancelButtonProps={{ className: 'mg-btn _green', 'data-test': 'profile_page_modal_cancel' }}
      >
        <p>{t('modal_delete_profile')}</p>
      </Modal>
    </div>
  );
};
