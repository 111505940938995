import Axios from 'axios';

import { signout, setStatusCode } from '../slices/authSlice';
import { setSpinnerVisible } from '../slices/commonSlice';
import { Store, History } from '../store';

export const STATUS_CODE = {
  OK: 200,
  UNAUTHORIZED: 401
};

export const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config) => {
    const state = Store.getState();
    if (config.isNeedSpinner && !state.common.isSpinnerVisible) {
      Store.dispatch(setSpinnerVisible(true));
    }
    if (process.env.REACT_APP_STATUS === 'local' && config.url.endsWith('/oauth2/url')) {
      config.url += '?local=true';
    }

    return config;
  };

  const onRequestFail = (error) => {
    return Promise.reject(error);
  };
  Axios.interceptors.request.use(onRequestSuccess, onRequestFail);

  const onResponseSuccess = (response) => {
    const { config } = response;
    if (config.isNeedSpinner) {
      Store.dispatch(setSpinnerVisible(false));
    }
    Store.dispatch(setStatusCode(STATUS_CODE.OK));
    return response;
  };

  const onResponseFail = (error) => {
    Store.dispatch(setSpinnerVisible(false));

    const status = error?.status || error?.response?.status;
    if (status === STATUS_CODE.UNAUTHORIZED) {
      Store.dispatch(signout());
      Store.dispatch(setStatusCode(STATUS_CODE.UNAUTHORIZED));
      History.push('/');
    }

    Store.dispatch(setStatusCode(status));

    return Promise.reject(error);
  };
  Axios.interceptors.response.use(onResponseSuccess, onResponseFail);
};
