import React, { useCallback, useEffect, useState } from 'react';

import { Grid, Layout, Menu } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { signinAsync, signoutAsync } from '../../api/auth';
import { getProfileAsync } from '../../api/profile';
import { SignInForm } from '../../modules/auth/signin/components/form/index';
import { signout } from '../../slices/authSlice';
import { logout } from '../../slices/profileSlice';
import { appMessage } from '../message';
import { typeMessage } from '../message/constants';
// import { loadScreeningDictAsync } from 'src/app/api/screen';
import { SessionStorageHelper } from 'src/app/helpers/sessionstore';
import { selectIsAuthenticated, setAuthToken } from 'src/app/slices/authSlice';
import { selectPortfolios } from 'src/app/slices/portfolioSlice';
import { selectProfile, setProfile } from 'src/app/slices/profileSlice';
import { selectScreeningsDictionary } from 'src/app/slices/screeningSlice';
import arrow from 'src/assets/svg/arrows/arrow-top.svg';
import logo from 'src/assets/svg/logo/logo.svg';
import { ReactComponent as MenuIcon } from 'src/assets/svg/menu.svg';

import { menuItemsArray, profileSubmenuItems } from './components/consts';
import { HeaderSearchCompany } from './components/header-search-company';
import { HoverMenu } from './components/hoverMenu';
import { ProfileMenuItem } from './components/profileMenuItem';
import styles from './Header.module.css';

import './style.css';

const { useBreakpoint } = Grid;
const { Header } = Layout;

export const AppHeader = () => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleMenuId, setVisibleMenuId] = useState(null);
  const [visibleMenuItems, setVisibleMenuItems] = useState([]);
  const [profileSubmenu, setProfileSubmenu] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const [isVisibleSearch, setIsVisibleSearch] = useState(true);

  // const [screenings, setScreenings] = useState([]);
  const screenings = useSelector(selectScreeningsDictionary);
  // const [screenPagination, setScreenPagination] = useState({
  //   limit: 100,
  //   index: 1,
  //   total: 0
  // });

  const profile = useSelector(selectProfile);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const yourPortfolios = useSelector(selectPortfolios);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const screens = useBreakpoint();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setProfile(null));
    }
  }, [dispatch, isAuthenticated, profile]);

  const handleLogout = useCallback(() => {
    // @ts-ignore
    dispatch(signoutAsync()).then(() => {
      dispatch(signout());
      dispatch(logout());
      setVisibleMenuItems([]);
      setVisibleMenuId(null);
      setVisibleMenu(false);
      SessionStorageHelper.removeSettingsFromStorage();
      navigate('/');
    });
  }, [dispatch, navigate]);

  const handleLeave = useCallback((e) => {
    setVisibleMenuId(null);
    setVisibleMenu(false);
    setVisibleMenuItems([]);
  }, []);

  const ChangePassword = useCallback(() => {
    navigate('/account/change-password');
    handleLeave();
  }, [navigate, handleLeave]);

  // const loadDictionariesScreen = useCallback(
  //   (index) => {
  //     dispatch(loadScreeningDictAsync(index - 1, screenPagination.limit, null, null, { isNeedSpinner: false }))
  //       .then((res) => {
  //         const { items, total } = res;
  //         setScreenPagination((prev) => ({
  //           ...prev,
  //           total
  //         }));
  //         const systemScreenings = items.filter((screen) => screen.type === 'system' || screen.type === 'ideas');

  //         // if (profile?.hasFreeSubscriptionAccess) {
  //         //   systemScreenings = systemScreenings.filter((screen) => screen.id === defaultScreeningId);
  //         // }

  //         setScreenings(systemScreenings);
  //       })
  //       .catch((e) => {
  //         appMessage(typeMessage.ERROR, e.message);
  //       });
  //   },
  //   [dispatch, screenPagination.limit]
  // );

  // useEffect(() => {
  //   if (profile) {
  //     loadDictionariesScreen(1);
  //   }
  // }, [loadDictionariesScreen, profile]);

  const checkRoles = useCallback(
    (roles) => {
      return roles.indexOf(profile?.roleId) !== -1;
    },
    [profile?.roleId]
  );

  const handleHover = useCallback(
    (items, id) => {
      if (items && items.length && (!visibleMenu || id !== visibleMenuId)) {
        setVisibleMenuItems(items.filter((item) => (item.roles ? checkRoles(item.roles) : true)));
        setVisibleMenu(true);
        setVisibleMenuId(id);
      } else {
        setVisibleMenu(false);
        setVisibleMenuItems([]);
        setVisibleMenuId(null);
      }
    },
    [checkRoles, visibleMenu, visibleMenuId]
  );

  useEffect(() => {
    const resultItems = profile
      ? menuItemsArray.map((item) => {
          if (item.id === 'products') {
            return {
              ...item,
              submenu: [
                {
                  titleKey: 'submenu_screenings',
                  items: [
                    ...screenings
                      .filter((screen) => screen.type === 'system' || screen.type === 'ideas')
                      .map((screen) => ({
                        title: screen.name,
                        titleKey: screen.name,
                        link: `/show-screen/${screen.id}`
                      })),
                    {
                      key: 'custom_screenings',
                      component: () => (
                        <span
                          className={styles.customScreeningItem}
                          onClick={() => {
                            navigate('/custom-screen/create');
                            handleLeave();
                          }}
                        >
                          {i18n.t('submenu_custom_screen')}
                        </span>
                      )
                    }
                  ]
                },
                ...item.submenu
              ]
            };
          }
          return item;
        })
      : menuItemsArray;
    setMenuItems(resultItems);
  }, [handleLeave, navigate, profile, screenings, i18n, yourPortfolios]);

  useEffect(() => {
    if (visibleMenuId === 'products') {
      const items = menuItems[0].submenu;
      setVisibleMenuItems(items);
    }
  }, [menuItems, visibleMenuId, screenings]);

  const loadProfile = useCallback(() => {
    dispatch(getProfileAsync())
      // @ts-ignore
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch(() => {
        dispatch(setProfile(null));
      });
  }, [dispatch]);

  const handleSubmitLogin = useCallback(
    (val) => {
      dispatch(signinAsync(val.email, val.password))
        // @ts-ignore
        .then((res) => {
          dispatch(setAuthToken(res.token));
          loadProfile();
          setVisibleMenuItems([]);
          setVisibleMenu(false);
          setVisibleMenuId(null);
          navigate('/');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch, loadProfile, navigate]
  );

  useEffect(() => {
    setProfileSubmenu(
      profileSubmenuItems(profile).map((item) => {
        const newItem = { ...item, items: [...item.items] };
        if (item.id === 'profile-help') {
          newItem.items[0].click = profile ? ChangePassword : null;
        }
        if (item.id === 'profile-personal') {
          if (profile) {
            newItem.items[3].click = handleLogout;
          } else {
            newItem.items[0].component = SignInForm;
            newItem.items[0].propsMethods = {
              onSubmit: handleSubmitLogin
            };
          }
        }
        return newItem;
      })
    );
  }, [handleLogout, handleSubmitLogin, profile, ChangePassword]);

  useEffect(() => {
    const logo = document.querySelector('#logo');
    if (isVisibleSearch && (screens.sm || screens.xs) && !screens.md) {
      logo.style.display = 'none';
    } else {
      logo.style.display = 'block';
    }
  }, [isVisibleSearch, screens.md, screens.sm, screens.xs]);

  const handleLeaveOnRouteChange = useCallback(() => {
    setVisibleMenuId(null);
    setVisibleMenu(false);
    setVisibleMenuItems([]);
  }, []);

  const handleChangeSearchVisibility = useCallback(() => {
    setIsVisibleSearch((prev) => !prev);
  }, []);

  const handleOnClickSubMenu = useCallback(() => {
    if (visibleMenu) {
      setVisibleMenu(false);
    }
  }, [visibleMenu]);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !isSafari) {
      const handleClickOutside = (event) => {
        const header = document.querySelector('.ant-layout-header');
        const overflowMenu = document.querySelector('.ant-menu-submenu-popup');
        if (header && !header.contains(event.target) && !overflowMenu?.contains(event.target)) {
          handleLeave();
          setIsVisibleSearch(true);
        }
      };
      document.addEventListener('mouseout', handleClickOutside, true);
      return () => {
        document.removeEventListener('mouseout ', handleClickOutside, true);
      };
    }
  }, [handleLeave]);

  return (
    <Header className={styles.mgHeader}>
      <div id="logo" className={styles.logo}>
        <Link data-test="header_logo" to="/">
          <img src={logo} />
        </Link>
      </div>

      <div
        className={
          isVisibleSearch && (screens.sm || screens.xs) && !screens.md
            ? `${styles.mgHeaderMenuWrap} ${styles.mgHeaderMenuWrapVisibleSearch}`
            : styles.mgHeaderMenuWrap
        }
      >
        {profile && (
          <div className={styles.search}>
            <HeaderSearchCompany onNavigate={handleChangeSearchVisibility} />
          </div>
        )}
        <Menu
          theme="light"
          mode="horizontal"
          className={profile ? ` ${styles.mgHeaderMenuLeft} ${styles.mgHeaderMenuLeftAuth}` : styles.mgHeaderMenuLeft}
          triggerSubMenuAction="click"
          overflowedIndicator={<MenuIcon />}
          onOpenChange={handleOnClickSubMenu}
        >
          {menuItems.map((item) => {
            if ((item.roles && checkRoles(item.roles)) || !item.roles) {
              return (
                <Menu.Item
                  data-test={`header_menu_${item.titleKey}`}
                  key={item.titleKey}
                  className={classNames(styles.mgHeaderMenu, {
                    [styles.mgHeaderMenuSelected]: visibleMenuId === item.id,
                    [styles.productsItem]: item.id === 'products',
                    [styles.resourcesItem]: item.id === 'resources'
                  })}
                  onClick={() => handleHover(item.submenu || [], item.id)}
                >
                  {visibleMenu && visibleMenuId === item.id && item.submenu && item.submenu.length && (
                    <img src={arrow} className={styles.arrowTop} />
                  )}
                  {item.link ? (
                    <Link
                      data-test={`header_${t(item.titleKey)}`}
                      onClick={() => handleLeaveOnRouteChange()}
                      to={item.link}
                    >
                      {t(item.titleKey)}
                    </Link>
                  ) : (
                    t(item.titleKey)
                  )}
                </Menu.Item>
              );
            } else {
              return null;
            }
          })}
          <ProfileMenuItem
            profile={profile}
            isSelected={visibleMenuId === 'profile'}
            onVisibleMenu={() => handleHover(profileSubmenu, 'profile')}
          />
        </Menu>

        <HoverMenu
          visibleMenuId={visibleMenuId}
          visibleMenu={visibleMenu}
          visibleMenuItems={visibleMenuItems}
          onClickItem={() => handleLeaveOnRouteChange()}
        />
      </div>
    </Header>
  );
};
