import React, { useState, useCallback, useEffect } from 'react';

import { Grid } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate, Routes, Route } from 'react-router-dom';

import { listOfNotificationsColumns } from '../consts';
import { loadAllNotificationsAsync } from 'src/app/api/polls-and-notifications';
import { AppSimpleTable } from 'src/app/components/table/index';
import { NotificationModel } from 'src/app/models/notifications';

import { NotificationEdit } from './components/notification-edit';
import { SearchPanel } from './components/search-panel/index';

export const Notifications = () => {
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState({ column: 'createdAt', direction: 'DESC' });
  const [pagination, setPagination] = useState({
    limit: 10,
    index: 1,
    total: 0
  });
  const [notificationsList, setNotificationsList] = useState([]);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadNotifications = useCallback(() => {
    dispatch(loadAllNotificationsAsync(pagination.index - 1, pagination.limit, searchValue, sort)).then((data) => {
      const { items, total } = data;
      setPagination((prevState) => {
        if (!items.length) {
          return {
            ...prevState,
            index: pagination.index > 1 ? pagination.index - 1 : 1,
            total: 0
          };
        }
        return {
          ...prevState,
          total
        };
      });
      setNotificationsList(
        items.map((info) => {
          return new NotificationModel({
            ...info,
            createdAt: moment(info?.createdAt).format('MMM D YYYY')
          });
        })
      );
    });
  }, [dispatch, pagination.index, pagination.limit, searchValue, sort]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const handleSearch = useCallback((val) => {
    setSearchValue(val);
  }, []);

  const handleOnAddNotification = useCallback(() => {
    navigate('notifications/creation');
  }, [navigate]);

  const handleChangePagination = useCallback((page, pageSize) => {
    setPagination((prev) => {
      return {
        ...prev,
        limit: pageSize,
        index: page
      };
    });
  }, []);

  const handleSelectRow = useCallback(
    (notification) => {
      navigate(`notifications/${notification.id}`);
    },
    [navigate]
  );

  const handleSort = useCallback((sort) => {
    setSort(sort);
  }, []);

  return (
    <div>
      <Routes>
        <Route
          index
          element={
            <>
              <SearchPanel onSearch={handleSearch} handleOnAddNotification={handleOnAddNotification} />
              <AppSimpleTable
                padding={screens.lg || screens.md ? '0 106px 0 106px' : screens.sm ? '0 60px 0 60px' : '0 0 0 0'}
                pagination={pagination}
                sort={sort}
                className={'_white-row'}
                listOfData={notificationsList}
                onSort={handleSort}
                columns={listOfNotificationsColumns}
                onChangePagination={handleChangePagination}
                onSelectRow={handleSelectRow}
              />
            </>
          }
        />
        <Route path={'/notifications/:notificationId'} element={<NotificationEdit />} />;
      </Routes>
    </div>
  );
};
