import React from 'react';

import styles from '../SearchColumnModal.module.css';
import { AppCheckbox } from 'src/app/components/form/checkbox';
/**
 *
 * @param {object} param0
 * @param {ColumnTypes.BaseColumnPrepared[]} param0.columns
 * @param {(e: ColumnTypes.BaseColumnPrepared) => void} param0.onChange
 */
export const BaseColumns = ({ columns, onChange }) => {
  return (
    <div className={styles.baseColumnsWrapper}>
      {columns?.map((elem) => {
        return (
          <AppCheckbox
            key={elem.key}
            value={elem.checked}
            label={elem.columnTitle}
            onChange={() => onChange({ ...elem, checked: !elem.checked })}
            className={styles.baseColumnCheckbox}
          />
        );
      })}
    </div>
  );
};
