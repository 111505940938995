import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  model: {},
  drawing: {},
  message: {
    isVisible: false,
    isSuccess: false,
    text: ''
  },
  savedUserData: null,
  savedDepth: null,
  // interactions - изменения для отправки на сервер
  interactions: null,
  maxYTicksWidth: null
};

const financialModelSlice = createSlice({
  name: 'financialModel',
  initialState,
  reducers: {
    setModel: (state, { payload }) => {
      const model = structuredClone(payload);
      state.model = model;
      model.drawing && (state.drawing = model.drawing);
    },
    setMessage: (state, { payload: { success, message } }) => {
      state.message = message
        ? {
            isVisible: true,
            isSuccess: success,
            text: message
          }
        : {
            ...state.message,
            isVisible: false
          };
    },
    setSavedUserData: (state, { payload }) => {
      state.savedUserData = payload;
    },
    setSavedDepth: (state, { payload }) => {
      state.savedDepth = payload;
    },
    setInteractions: (state, { payload }) => {
      state.interactions = payload;
    },
    setMaxYTicksWidth: (state, { payload }) => {
      state.maxYTicksWidth = payload;
    },
    resetFinancialModel: () => initialState
  }
});

export const {
  setModel,
  setMessage,
  setSavedUserData,
  setSavedDepth,
  setInteractions,
  setMaxYTicksWidth,
  resetFinancialModel
} = financialModelSlice.actions;
export const selectModel = (state) => state.financialModel.model;
export const selectDrawing = (state) => state.financialModel.drawing;
export const selectGraphicsData = (state) => state.financialModel.model.run_data;
export const selectUserData = (state) => state.financialModel.model.user_data;
export const selectMessage = (state) => state.financialModel.message;
export const selectSavedUserData = (state) => state.financialModel.savedUserData;
export const selectSavedDepth = (state) => state.financialModel.savedDepth;
export const selectInteractions = (state) => state.financialModel.interactions;
export const selectMaxYTicksWidth = (state) => state.financialModel.maxYTicksWidth;

export default financialModelSlice.reducer;
