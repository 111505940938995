import React, { useCallback } from 'react';

import { Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { FiltersList } from './filtersList';
import styles from './SavedFiltersModal.module.css';

export const SavedFilters = ({ visible, onChangeVisible }) => {
  const { t } = useTranslation();

  const filters = [
    {
      id: 1,
      title: 'LongLong Long Filter Name Number One here'
    },
    {
      id: 2,
      title: 'LongLong Long Filter Name Number One here'
    },
    {
      id: 3,
      title: 'LongLong Long Filter Name Number One here'
    },
    {
      id: 4,
      title: 'LongLong Long Filter Name Number One here'
    },
    {
      id: 5,
      title: 'LongLong Long Filter Name Number One here'
    },
    {
      id: 6,
      title: 'LongLong Long Filter Name Number One here'
    },
    {
      id: 7,
      title: 'LongLong Long Filter Name Number One here'
    },
    {
      id: 8,
      title: 'LongLong Long Filter Name Number One here'
    }
  ];

  const handleOk = () => {
    onChangeVisible(null);
  };

  const handleDeleteFilter = useCallback(() => {}, []);

  return (
    <Modal
      title={''}
      centered
      width={800}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('load_filter_button')}
      cancelText={t('cancel')}
      getContainer={'main'}
      data-test={'saved_filters_modal'}
      okButtonProps={{
        className: `mg-btn _green ${styles.button}`,
        'data-test': 'saved_filters_modal_load_filter'
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        'data-test': 'saved_filters_modal_cancel'
      }}
      onOk={handleOk}
      onCancel={() => onChangeVisible(null)}
    >
      <p className={styles.titleModal}>{t('load_filter_title')}</p>
      <Divider className={styles.divider} />
      <FiltersList filters={filters} onDelete={handleDeleteFilter} />
    </Modal>
  );
};
