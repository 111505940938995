import { axiosErrors, axiosGet } from '../helpers/axios';

export const loadMenuAsync =
  (options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadMenu = axiosGet(`mgapi/resources/content`, options);
    return loadMenu()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const loadArticleAsync =
  (id, options = { isNeedSpinner: false }) =>
  (dispatch) => {
    const loadArticle = axiosGet(`mgapi/resources/articles/${id}`, options);
    return loadArticle()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };
