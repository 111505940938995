import { axiosGet, axiosPost, axiosErrors, axiosDelete, axiosPut } from '../helpers/axios';
import { getRangeFilterValueWithUnit } from 'src/app/components/table-difficult/components/filter-table-row/helpers';

export const loadScreeningDictAsync =
  (index, limit, sort, where, options = { isNeedSpinner: true }) =>
  (dispatch) => {
    let whereStr = '';
    if (where) {
      Object.entries(where)?.forEach(([key, value]) => {
        whereStr += `&where[${key}]=${value}`;
      });
    }

    const loadScreeningDict = axiosGet(
      `dictionaries/screenings?index=${index || 0}&limit=${limit || 10}${
        sort ? `&order=${sort.column}&order=${sort.direction}` : ''
      }${where ? whereStr : ''}`,
      options
    );
    return loadScreeningDict()
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

export const loadFiltersAndColsAsync = (id) => () => {
  const loadScreeningParams = axiosGet(`screenings/${id}/edit`);
  return loadScreeningParams()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

/** @type {(template: string) => () => Promise<ColumnTypes.ApiColumns>} */
export const searchColAsync = (template) => () => {
  const search = axiosGet(`screenings/column?template=${template}`);
  return search()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

/** @type {() => () => Promise<ColumnTypes.ApiColumns>} */
export const searchBaseColAsync = () => () => {
  const search = axiosGet('screenings/base-column');
  return search()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const createScreenAsync = (body) => () => {
  const create = axiosPost(`screenings`);
  return create(body)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

/** @type {(id: string, name: string, columns: ColumnTypes.Column[], sort: ColumnTypes.ColumnSort[]) => () => Promise<void>} */
export const updateScreenAsync = (id, name, columns, sort) => () => {
  const update = axiosPut(`screenings/${id}/edit`);
  return update({
    name,
    columns: {
      ...prepareColumns(columns || [])
    },
    filter: {
      ...prepareFilters(columns || [])
    },
    sort: [...generateSortList(sort)]
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const deleteScreenAsync = (id) => () => {
  const deleteScreen = axiosDelete(`screenings/${id}`);
  return deleteScreen()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const saveScreenOptionsAsync = (id, body) => () => {
  const save = axiosPost(`screenings/${id}/options`);
  return save(body)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const searchFilterAsync = (name) => () => {
  const search = axiosGet(`screenings/filter?column_name=${name}`);
  return search()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

export const searchIndustryAsync = (template) => () => {
  const search = axiosGet(`dictionaries/industries?title=${template}`);
  return search()
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw axiosErrors(e);
    });
};

/**
 * @param {string} id screening id
 * @param {number} index
 * @param {number} limit
 * @param {ColumnTypes.Column[]} columns
 * @param {ColumnTypes.ColumnSort[]} sort
 * @param {object} [options]
 * @return {() => Promise<ColumnTypes.ScreenApiResponse>}
 */
export const loadScreeningAsync =
  (id, index, limit, columns, sort, options = { isNeedSpinner: false, signal: null }) =>
  () => {
    const loadScreening = axiosPost(
      `screenings/${id}?index=${index}&limit=${limit}${sort ? generateSortString(sort) : ''}`,
      options
    );
    return loadScreening(
      columns
        ? {
            columns: {
              ...prepareColumns(columns)
            },
            filter: {
              ...prepareFilters(columns)
            }
          }
        : {}
    )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw axiosErrors(e);
      });
  };

/** @type {(sort: ColumnTypes.ColumnSort[]) => string} */
const generateSortString = (sort) => {
  return sort.reduce((acc, next, index) => {
    return (acc += `&order[${index}]=${next.column}&order[${index}]=${next.direction}`);
  }, '');
};

/** @type {(sort: ColumnTypes.ColumnSort[]) => [string, string][]} */
export const generateSortList = (sort) => {
  return sort.map((e) => [e.column, e.direction]);
};

/** @type {(cols: ColumnTypes.Column[]) => ColumnTypes.ApiFilters} */
const prepareFilters = (cols) => {
  /** @type {ColumnTypes.ApiFilters} */
  const res = {};

  cols.forEach(({ key, selectedFilter }) => {
    if (selectedFilter?.value) {
      res[key] = {
        name: selectedFilter.name,
        value: selectedFilter.unit
          ? getRangeFilterValueWithUnit(selectedFilter.value, selectedFilter.unit)
          : selectedFilter.value
      };
    }
  });

  return res;
};

/** @type {(cols: ColumnTypes.Column[]) => ColumnTypes.ApiColumns} */
const prepareColumns = (cols) => {
  return cols.reduce((acc, next, index) => {
    return Object.assign(acc, {
      [next.key]: {
        // @ts-ignore
        editable: next.editable,
        visible: next.isVisible,
        order: index
      }
    });
  }, {});
};
