import React, { useState } from 'react';

import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import AppInput from 'src/app/components/form/input';

import styles from './SaveAsScreenModal.module.css';

export const SaveAsScreenModal = ({ visible, onChangeVisible }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const saveFilterInitialValues = {
    name: ''
  };

  const handleOk = () => {
    onChangeVisible(form.getFieldsValue());
  };

  const setDisabled = () => {
    const value = form.getFieldsValue();
    setIsDisabled(!!value?.name);
  };

  return (
    <Modal
      title={t('save_as_screen_modal_title')}
      centered
      width={460}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('save')}
      cancelText={t('cancel')}
      getContainer={'main'}
      okButtonProps={{
        className: `mg-btn _green ${styles.button}`,
        'data-test': 'custom_screen_page_save_filter_modal_add',
        disabled: !isDisabled
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        'data-test': 'custom_screen_page_save_filter_modal_cancel'
      }}
      onOk={() => handleOk()}
      onCancel={() => onChangeVisible(null)}
    >
      <div className={styles.formWrap}>
        <Form
          name="saveAsScreen"
          form={form}
          initialValues={saveFilterInitialValues}
          autoComplete="off"
          onFieldsChange={setDisabled}
          className={styles.form}
        >
          <Form.Item className={styles.formItem} name="name">
            <AppInput
              data-test="custom_screen_page_save_filter"
              placeholder={t('name')}
              className={styles.input}
            ></AppInput>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
