/**
 *
 * @param {Array<>} arr
 * @param {number} from
 * @param {number} to
 * @returns {Array<>}
 */
export const swapItems = (arr, from, to) => {
  const updatedArr = [...arr];
  Array.prototype.splice.call(updatedArr, to, 0, Array.prototype.splice.call(updatedArr, from, 1)[0]);
  return updatedArr;
};
