import React from 'react';

import { Tooltip } from 'antd';

import styles from './ShowScreen.module.css';

/** @type {(apiSort: [string, string][], columns: ColumnTypes.ApiColumns) => ColumnTypes.ColumnSort[]} */
export const unpackSort = (apiSort, columns) => {
  /** @type {ColumnTypes.ColumnSort[]} */
  const result = [];
  apiSort.forEach((e) => {
    const col = columns[e[0]];
    if (col) {
      if (e[1] === 'ASC') {
        result.push({ column: e[0], direction: 'ASC', sortTitle: col.groupName || col.title });
      } else {
        result.push({ column: e[0], direction: 'DESC', sortTitle: col.groupName || col.title });
      }
    }
  });
  return result;
};

export const getInfoColumnTemplate = () => {
  return {
    sorted: false,
    filtered: false,
    width: 48,
    isVisible: true,
    fixed: 'right',
    key: '',
    render: () => {}
  };
};

export const getElementOfTableValue = (value) => {
  const lookupNumbers = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M', color: 'var(--text-light-gray)' },
    { value: 1e9, symbol: 'B', color: 'var(--title-text-blue)' },
    { value: 1e12, symbol: 'T', color: 'var(--yellow)' },
    { value: 1e15, symbol: 'P', color: 'var(--text-light-gray)' },
    { value: 1e18, symbol: 'E' }
  ];

  const lookupStrings = [
    { value: 'good', color: 'var(--green)' },
    { value: 'poor', color: 'var(--error)' },
    { value: 'average', color: 'var(--yellow)' }
  ];
  const isNumber = !isNaN(parseFloat(value));
  const findString = lookupStrings.find((el) => el.value === value);

  if (isNumber && value && value.toString().includes('.')) {
    let item = lookupNumbers[0];
    const absValue = Math.abs(value);

    if (absValue > 1) {
      item = lookupNumbers
        .slice()
        .reverse()
        .find((elem) => {
          return absValue >= elem.value;
        });
    }
    const result = parseFloat(value) / item.value;
    const [intPart, fractPart] = result.toFixed(2).toString().split('.');
    return (
      <p className={styles.numberWrap}>
        {`${numberWithSpaces(intPart.toString().replace('.', ' '))}.`}
        <span style={{ color: 'var(--text-light-gray)' }}>{fractPart}</span>
        <span style={{ color: item.color }}>{` ${item.symbol}`}</span>
      </p>
    );
  } else if (findString) {
    return (
      <Tooltip title={value}>
        <span style={{ color: findString.color, textTransform: 'capitalize' }}>{value}</span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={value}>
        <span>{value}</span>
      </Tooltip>
    );
  }
};

/** @type {(x: string) => string} */
const numberWithSpaces = (x) => {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
