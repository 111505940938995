import React from 'react';

import { Col, Divider, Grid, Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from 'src/assets/svg/logo/white-logo.svg';

import styles from './Footer.module.css';

const { Footer } = Layout;
const { useBreakpoint } = Grid;
const AppFooter = () => {
  const { t } = useTranslation();

  const screens = useBreakpoint();

  return (
    <Footer
      className={
        screens.md ? `${styles.footer} ${styles.footerPadding}` : `${styles.footer} ${styles.footerPaddingSmallScreen}`
      }
    >
      <Row align="top">
        <Col lg={6} xl={4}>
          <Link data-test="footer_logo" to="/">
            <img src={logo} />
          </Link>
        </Col>
        <Col lg={18} xl={20} className={styles.footerMenu} style={screens.lg ? {} : { marginTop: 20 }}>
          <p>
            <Link data-test="footer_contact_us" to="/contact-support">
              {t('footer_contact_us')}
            </Link>{' '}
            {t('footer_see_our')}{' '}
            <Link data-test="footer_privacy_policy" to="/privacy-policy">
              {t('footer_privacy_policy')}
            </Link>{' '}
            {t('and')}{' '}
            <Link data-test="footer_terms_conditions" to="/terms-and-conditions">
              {t('footer_terms_and_conditions')}
            </Link>
            .
          </p>
          <Divider className={styles.divider} />
          <Row align="top" className={styles.footerLinksWrap}>
            <Col md={12}>
              <p>{t('footer_project_description')}</p>
            </Col>
            <Col md={12}>
              <p style={screens.md ? {} : { marginTop: 20 }}>{t('footer_disclaimer_of_liability')}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
