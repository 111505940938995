import React from 'react';

import { Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { requestSupportAsync } from 'src/app/api/support';
import { AppSelect } from 'src/app/components/form/select';
import { AppTextarea } from 'src/app/components/form/text-area';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import styles from './Form.module.css';
import './style.css';

export const SupportForm = ({ topics }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmit = (val) => {
    dispatch(requestSupportAsync(val.topic, val.message))
      .then(() => {
        appMessage(typeMessage.SUCCESS, 'support_success_message');
        form.resetFields();
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  };

  return (
    <Form
      name="requestSupport"
      form={form}
      onFinish={handleSubmit}
      autoComplete="off"
      className={`${styles.supportForm} support`}
    >
      <Form.Item
        name="topic"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: t('required_input')
          }
        ]}
      >
        <AppSelect
          data-test="profile_page_select_topics"
          placeholder={t('support_select_placeholder')}
          options={topics}
        />
      </Form.Item>
      <Form.Item
        name="message"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: t('required_input')
          }
        ]}
      >
        <AppTextarea data-test="profile_page_message" placeholder={t('message')}></AppTextarea>
      </Form.Item>
      <Form.Item shouldUpdate className={styles.formItem}>
        {() => (
          <Button
            data-test="profile_page_send_support"
            htmlType="submit"
            className="mg-btn"
            disabled={
              !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
            }
          >
            {t('send')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
