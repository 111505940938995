import React, { useCallback, useState, useEffect } from 'react';

import { Grid } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { inviteAsync } from 'src/app/api/notification';
import { getSentInvitationsAsync } from 'src/app/api/profile';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { AppSimpleTable } from 'src/app/components/table';
import { selectProfile } from 'src/app/slices/profileSlice';
import titleIcon from 'src/assets/svg/myInvitationsIcon.svg';

import { InvitationStatus } from './components/invitation-status';
import { InviteForm } from './components/inviteForm';
import { columns } from './constants';
import styles from './Invite.module.css';
import './style.css';

const { useBreakpoint } = Grid;
export const Invite = () => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();
  const [sort] = useState({ column: 'date', direction: 'desc' });
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [activeInvites, setActiveInvites] = useState(0);
  const [pagination, setPagination] = useState({
    limit: 15,
    index: 1,
    total: 0
  });
  const screens = useBreakpoint();

  const prepareData = useCallback(
    (arr) => {
      return arr.map((item, index) => {
        const number = pagination.total - pagination.limit * (pagination.index - 1) - index || 0;
        return {
          id: index,
          index: number,
          to: item.email,
          date: moment(item.createdAt).format('MMM D YYYY'),
          status: <InvitationStatus activatedAt={item.activatedAt} expiredAt={item.expiredAt} />
        };
      });
    },
    [pagination.index, pagination.limit, pagination.total]
  );

  const loadInvitations = useCallback(() => {
    dispatch(getSentInvitationsAsync(pagination.index - 1, pagination.limit, 'createdAt', 'desc'))
      .then((data) => {
        const { items, total, totalActive } = data;
        setActiveInvites(totalActive);
        setInvitedUsers(prepareData(items));
        setPagination((prevState) => ({
          ...prevState,
          total
        }));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  }, [dispatch, pagination.index, pagination.limit, prepareData]);

  const handleSubmit = useCallback(
    (val) => {
      dispatch(inviteAsync(val.email, val.recipient, val.extraInformation, val.roleId))
        .then(() => {
          loadInvitations();
          appMessage(typeMessage.SUCCESS, 'invite_success_message');
        })
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    },
    [dispatch, loadInvitations]
  );

  useEffect(() => {
    loadInvitations();
  }, [dispatch, pagination.index, pagination.limit, prepareData, loadInvitations]);

  const handleChangePagination = useCallback((page, pageSize) => {
    setPagination({
      index: page,
      limit: pageSize
    });
  }, []);

  return (
    <div className="invite">
      <div className={styles.paddings}>
        <div className={styles.title}>
          <h1 className="page__title-uppercase">{t('title_invite')}</h1>
          <img src={titleIcon} className={styles.logo} />
        </div>
        {profile && profile.config ? (
          <div className={styles.info}>
            <div data-test="invitations_page_remain_block" className={`${styles.infoBlock} ${styles.remainBlock}`}>
              <span className={styles.titleInfoBlock}>{t('invitations_remain')}</span>
              <span className={styles.count}>{profile.config.inviteLimit - activeInvites}</span>
            </div>
            <div data-test="invitations_page_sent_block" className={styles.infoBlock}>
              <span className={styles.titleInfoBlock}>{t('invitations_sent')}</span>
              <span className={styles.count}>{pagination.total}</span>
            </div>
          </div>
        ) : (
          ''
        )}
        <hr />
        <InviteForm onSubmit={handleSubmit} />
      </div>
      {invitedUsers ? (
        <>
          <AppSimpleTable
            padding={screens.lg || screens.md ? '0 106px 0 106px' : screens.sm ? '30px 60px 0 60px' : '0 0 0 0'}
            columns={columns}
            sort={sort}
            listOfData={invitedUsers}
            pagination={pagination}
            onChangePagination={handleChangePagination}
          />
          {pagination.limit >= pagination.total ? <div style={{ paddingBottom: '120px' }}></div> : ''}
        </>
      ) : (
        ''
      )}
    </div>
  );
};
