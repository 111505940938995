import { PrivacyPolicy } from '../components/footer/components/privacy-policy';
import { TermsAndConditions } from '../components/footer/components/terms-and-conditions';
import { AccessToGroup } from '../modules/access-to-group/index';
import { Account } from '../modules/account';
import { AccountAlerts } from '../modules/account-alerts/index';
import { PeriodSubscription } from '../modules/account-subscription/components/period-subscription';
import { AccountSubscription } from '../modules/account-subscription/index';
// import { Admins } from '../modules/admin';
import { Auth } from '../modules/auth/signin';
import { ChangePassword } from '../modules/change-password/index';
// import { Company } from '../modules/company';
import { CustomScreenCreate } from '../modules/custom-screen-create/index';
// import { Diagrams } from '../modules/diagrams/index';
// import { Home } from '../modules/home';
import { Ideas } from '../modules/ideas';
import { Invite } from '../modules/invite';
import { RequestInvitation } from '../modules/invite/requestInvitation';
import { NotificationEdit } from '../modules/notifications-and-polls/components/notifications/components/notification-edit/index';
import { PollEdit } from '../modules/notifications-and-polls/components/polls/components/poll-edit/index';
import { NotificationsAndPolls } from '../modules/notifications-and-polls/index';
import { PasswordRecoveryFirstStep } from '../modules/password-recovery/components/first-step/firstStep';
import { SetPasswordSteps } from '../modules/password-recovery/components/set-password-steps/setPasswordSteps';
// import { Portfolio } from '../modules/portfolio';
// import { DetailsPortfolio } from '../modules/portfolio/components/details-portfolio';
import { Products } from '../modules/products';
import { Resources } from '../modules/resources';
// import { Screenings } from '../modules/screenings';
import { SignUp } from '../modules/sign-up/index';
import { ContactSupport } from '../modules/support';
import { SystemSettings } from '../modules/system-settings/index';
import { WhyMG } from '../modules/why-mg/index';

import { generatePromise, generateLazy } from './helpers';

const ShowScreen = generateLazy(generatePromise(() => import('../modules/show-screen/index'), 'ShowScreen'));
const Screenings = generateLazy(generatePromise(() => import('../modules/screenings'), 'Screenings'));
const Portfolio = generateLazy(generatePromise(() => import('../modules/portfolio'), 'Portfolio'));
const DetailsPortfolio = generateLazy(
  generatePromise(() => import('../modules/portfolio/components/details-portfolio'), 'DetailsPortfolio')
);
const Company = generateLazy(generatePromise(() => import('../modules/company'), 'Company'));
const Home = generateLazy(generatePromise(() => import('../modules/home'), 'Home'));
const Admins = generateLazy(generatePromise(() => import('../modules/admin'), 'Admins'));

export const routes = [
  {
    path: '/login/:type',
    exact: true,
    component: Auth,
    name: 'login',
    isProtected: false
  },
  {
    path: '/',
    exact: true,
    component: Home,
    name: 'root',
    isProtected: false
  },
  {
    path: '/:hash',
    exact: true,
    component: Home,
    name: 'root',
    isProtected: false
  },
  {
    path: '/invite',
    exact: true,
    component: Invite,
    name: 'invite',
    isProtected: true
  },
  {
    path: '/admin/*',
    exact: true,
    component: Admins,
    name: 'admin',
    isProtected: true
  },
  {
    path: '/invitation-request',
    exact: true,
    component: RequestInvitation,
    name: 'invitationRequest',
    isProtected: false
  },
  {
    path: '/custom-screen/create',
    exact: true,
    component: CustomScreenCreate,
    name: 'customScreen',
    isProtected: true
  },
  {
    path: '/system-settings',
    exact: true,
    component: SystemSettings,
    name: 'systemSettings',
    isProtected: true
  },
  {
    path: '/show-screen/:id',
    exact: true,
    component: ShowScreen,
    name: 'showScreen',
    isProtected: true
  },
  {
    path: '/account',
    exact: true,
    component: Account,
    name: 'account',
    isProtected: true
  },
  {
    path: '/account/:hash',
    exact: true,
    component: Account,
    name: 'account',
    isProtected: true
  },
  {
    path: '/account/accept-group-invite/:hash',
    exact: true,
    component: AccessToGroup,
    name: 'accept-group-invite',
    isProtected: false
  },
  {
    path: '/account/change-password',
    exact: true,
    component: ChangePassword,
    name: 'change-password',
    isProtected: true
  },
  {
    path: '/account/recover-password',
    exact: true,
    component: PasswordRecoveryFirstStep,
    name: 'password-recovery',
    isProtected: false
  },
  {
    path: '/account/recover-password/:hash',
    exact: true,
    component: SetPasswordSteps,
    name: 'password-recovery-set-password',
    isProtected: false
  },
  {
    path: '/account/sign-up/:hash',
    exact: true,
    component: SignUp,
    name: 'signup',
    layout: 'empty',
    isProtected: false
  },
  {
    path: '/account/subscription',
    exact: true,
    component: AccountSubscription,
    name: 'account-subscription',
    isProtected: true
  },
  {
    path: '/account/subscription-period/:id',
    exact: true,
    component: PeriodSubscription,
    name: 'subscription-period',
    isProtected: true
  },
  {
    path: '/account/alerts',
    exact: true,
    component: AccountAlerts,
    name: 'alerts',
    isProtected: true
  },
  {
    path: '/products/*',
    exact: true,
    component: Products,
    name: 'products',
    isProtected: true
  },
  {
    path: '/why-mg',
    exact: true,
    component: WhyMG,
    name: 'why-mg',
    isProtected: false
  },
  // {
  //   path: '/diagrams',
  //   exact: true,
  //   component: Diagrams,
  //   name: 'diagrams',
  //   isProtected: true
  // },
  {
    path: '/my-screenings',
    exact: true,
    component: Screenings,
    name: 'screenings',
    isProtected: true
  },
  {
    path: '/company/overview/:id',
    exact: true,
    component: Company,
    name: 'company',
    isProtected: true
  },
  {
    path: '/portfolio',
    exact: true,
    component: Portfolio,
    name: 'portfolio',
    isProtected: true
  },
  {
    path: '/portfolio-details/:id',
    exact: true,
    component: DetailsPortfolio,
    name: 'portfolio-details',
    isProtected: true
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    name: 'privacy-policy',
    isProtected: false
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    component: TermsAndConditions,
    name: 'terms-and-conditions',
    isProtected: false
  },
  {
    path: '/contact-support',
    exact: true,
    component: ContactSupport,
    name: 'contact-support',
    isProtected: false
  },
  {
    path: '/ideas',
    exact: true,
    component: Ideas,
    name: 'ideas',
    isProtected: true
  },
  {
    path: '/resources/articles',
    exact: true,
    component: Resources,
    name: 'resources',
    isProtected: true
  },
  {
    path: '/resources/articles/:articleId',
    exact: true,
    component: Resources,
    name: 'resources',
    isProtected: true
  },
  {
    path: '/notifications-and-polls/*',
    exact: true,
    component: NotificationsAndPolls,
    name: 'notifications-and-polls',
    isProtected: true
  },
  {
    path: '/notifications-and-polls/polls/:pollId',
    exact: true,
    component: PollEdit,
    name: 'poll',
    isProtected: true
  },
  {
    path: '/notifications-and-polls/polls/creation',
    exact: true,
    component: PollEdit,
    name: 'new-poll',
    isProtected: true
  },
  {
    path: '/notifications-and-polls/notifications/:notificationId',
    exact: true,
    component: NotificationEdit,
    name: 'notification',
    isProtected: true
  },
  {
    path: '/notifications-and-polls/notifications/creation',
    exact: true,
    component: NotificationEdit,
    name: 'new-notification',
    isProtected: true
  }
];
