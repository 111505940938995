import React, { useEffect, useState, useCallback, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import { loadMenuAsync, loadArticleAsync } from 'src/app/api/resources';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

import { MenuItem } from './components/menuItem';
import styles from './Resources.module.css';

export const Resources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { articleId } = useParams();
  const { hash } = useLocation();

  const [menu, setMenu] = useState();
  const [selectedMenuParams, setSelectedMenuParams] = useState();
  const [currentArticle, setCurrentArticle] = useState({
    id: '',
    text: ''
  });

  const articleContainerRef = useRef();

  useEffect(() => {
    dispatch(loadMenuAsync())
      .then((menuItems) => {
        setMenu(completeMenu(menuItems));
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const completeMenu = useCallback((menuItems) => {
    return menuItems.map((menuItem) => ({
      ...menuItem,
      ...(menuItem.articles && { articles: completeMenu(menuItem.articles) }),
      articleId: menuItem.link.split('#')[0].split('/')[2],
      anchor: menuItem.link.split('#')[1] || ''
    }));
  }, []);

  useEffect(() => {
    setSelectedMenuParams([articleId, hash]);

    if (!articleId) {
      return;
    }

    if (articleId === currentArticle.id) {
      scrollToAnchor(hash.slice(1));
      return;
    }

    dispatch(loadArticleAsync(articleId))
      .then((article) => {
        setCurrentArticle({ id: articleId, text: article });

        if (hash) {
          setTimeout(() => {
            scrollToAnchor(hash.slice(1));
          });
        }
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, hash]);

  const scrollToAnchor = useCallback((anchor) => {
    const targetElement = document.getElementsByName(anchor)[0];

    if (targetElement) {
      const headerOffset = 100;
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
        top: offsetPosition
      });
    }
  }, []);

  const onLinkClick = useCallback(
    (event) => {
      event.preventDefault();

      const anchor = event.target.href?.split('#')[1];
      if (`#${anchor}` === hash) {
        scrollToAnchor(anchor);
      } else {
        navigate(`#${anchor}`);
      }
    },
    [hash, navigate, scrollToAnchor]
  );

  useEffect(() => {
    const links = articleContainerRef.current?.querySelectorAll('a');

    links.forEach((link) => {
      link.addEventListener('click', onLinkClick);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', onLinkClick);
      });
    };
  }, [currentArticle.text, onLinkClick]);

  return (
    <div className={styles.page}>
      <div className={styles.menu}>
        <ul>
          {menu?.map((item, i) => (
            <MenuItem key={i} item={item} selectedMenuParams={selectedMenuParams} scrollToAnchor={scrollToAnchor} />
          ))}
        </ul>
      </div>
      <div ref={articleContainerRef} dangerouslySetInnerHTML={{ __html: currentArticle.text }}></div>
    </div>
  );
};
