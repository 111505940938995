import React, { useCallback, useEffect } from 'react';

import { Divider, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loadSystemSettingsAsync, updateSystemSettingsAsync, updateCronTimeAsync } from 'src/app/api/system-settings';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { selectSystemSettings, setSystemSettings } from 'src/app/slices/commonSlice';

import { TabContent } from './components/tab-content';
import styles from './SystemSettings.module.css';
import './styles.css';

const { TabPane } = Tabs;

export const SystemSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tabFirstKeys = [
    'session.service',
    'account.service',
    'temporary.service',
    'graphics.service',
    'company.service'
  ];
  const tabThirdKeys = ['oauth.service'];
  const tabSecondKeys = ['alert.service', 'notification.service'];
  const dispatch = useDispatch();
  const settings = useSelector(selectSystemSettings);

  const loadSettings = useCallback(() => {
    dispatch(loadSystemSettingsAsync())
      // @ts-ignore
      .then((data) => {
        dispatch(setSystemSettings(data));
      })
      .catch((err) => {
        appMessage(typeMessage.ERROR, err);
      });
  }, [dispatch]);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const handleSaveSettings = useCallback(
    (data) => {
      const result = settings.map((set) => {
        const elem = data.find((dt) => dt.type === set.type);
        return elem ?? set;
      });

      dispatch(updateSystemSettingsAsync(result))
        // @ts-ignore
        .then(() => {
          const alertService = result.find((el) => el.data && el.data.cronPeriod);
          dispatch(updateCronTimeAsync(alertService.data.cronPeriod));
          appMessage(typeMessage.SUCCESS, 'success_update_setting');
        })
        .catch((err) => {
          appMessage(typeMessage.ERROR, err);
        });
    },
    [dispatch, settings]
  );

  return (
    <div className="page">
      <h1 className={`${styles.title} page__title-uppercase`}>{t('system_title')}</h1>
      <div className={`${styles.tabsWrap} tabs-wrap`}>
        <Tabs
          defaultActiveKey={location.hash ? location.hash.replace('#', '') : '1'}
          onChange={(key) => {
            navigate(`${location.pathname}#${key}`);
          }}
          className={styles.tabsContainer}
        >
          <TabPane forceRender className={styles.tab} tab={t('system_tab_first_title')} key="1">
            <TabContent onSaveSettings={handleSaveSettings} settings={settings} tabKeys={tabFirstKeys}></TabContent>
          </TabPane>
          <TabPane forceRender className={styles.tab} tab={t('system_tab_second_title')} key="2">
            <TabContent onSaveSettings={handleSaveSettings} settings={settings} tabKeys={tabSecondKeys}></TabContent>
          </TabPane>
          <TabPane forceRender className={styles.tab} tab={t('system_tab_third_title')} key="3">
            <TabContent onSaveSettings={handleSaveSettings} settings={settings} tabKeys={tabThirdKeys}></TabContent>
          </TabPane>
        </Tabs>

        <Divider className={`${styles.divider} ${styles.dividerTabs}`} />
      </div>
    </div>
  );
};
