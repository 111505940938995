import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';

import { ClearOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { useDispatch, useSelector } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { FilterTableRow } from '../filter-table-row/index';
import { SaveAsScreenModal } from '../save-as-screen-modal/index';
import { SavedFilters } from '../saved-filters-modal/index';
import { SearchColumn } from '../search-modal/search-column-modal/index';
import { SearchIndustry } from '../search-modal/search-industry-modal';
import { selectNames, setScrollPosition, setUpdateScrollPosition } from 'src/app/slices/screeningSlice';
import { ReactComponent as ReorderIcon } from 'src/assets/svg/custom-screen/reorder.svg';
import { ReactComponent as ShowIcon } from 'src/assets/svg/custom-screen/show-eye.svg';
import { ReactComponent as TrashIcon } from 'src/assets/svg/custom-screen/trash.svg';
import { ReactComponent as QualityIcon } from 'src/assets/svg/quality.svg';
import { ReactComponent as QuestionIcon } from 'src/assets/svg/question-mark.svg';
import { ReactComponent as AddColSvg } from 'src/assets/svg/table/add-col.svg';
import { ReactComponent as DiagramIcon } from 'src/assets/svg/table/diagram.svg';
import { ReactComponent as SaveSvg } from 'src/assets/svg/table/save-filters.svg';

import { swapItems } from './helpers';
import styles from './SettingColumnsStyles.module.css';

import './styles.css';

const DragHandle = SortableHandle(() => <ReorderIcon className="dragged" />);
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

/**
 * @param {object} param0
 * @param {ColumnTypes.Column[]} param0.data columns layout as prop
 * @param {any} param0.onChangeCustomColumns
 * @param {boolean} [param0.isSettingsChanged]
 * @param {boolean} param0.hasSave
 * @param {boolean} param0.hasSaveAs
 * @param {(cols: ColumnTypes.BaseColumnPrepared[]) => void} param0.onSelectColumns
 * @param {(name: string, filter: ColumnTypes.Filter[]) => void} param0.onSelectFilter
 * @param {any} [param0.onDeleteFilter]
 * @param {any} param0.onClearFilters
 * @param {() => void} [param0.onReset]
 * @param {() => void} [param0.onSaveScreen]
 * @param {any} param0.onSaveAsScreen
 * @param {any} param0.onChangeFilter
 * @param {any} param0.updateLayout
 */
const SettingsColumnsTableComponent = ({
  data, // columns layout as prop
  onChangeCustomColumns,
  // isEditable,
  isSettingsChanged,
  hasSave, // has `Save` button
  hasSaveAs, // has `Save As` button
  onSelectColumns,
  onSelectFilter,
  onDeleteFilter,
  onClearFilters,
  onReset,
  onSaveScreen, // call back when `Save` button pressed
  onSaveAsScreen, // call back when `Save As` button pressed
  onChangeFilter,
  updateLayout
}) => {
  const dispatch = useDispatch();

  /** @type {UseHelper.UseStateType<ColumnTypes.Column[]>} */
  const [sortedColumns, setSortedColumns] = useState(data);
  const [openModal, setOpenModal] = useState(false);
  const [openSaveFilterModal, setOpenSaveFilterModal] = useState(false);
  const [openLoadFilterModal, setLoadSaveFilterModal] = useState(false);
  const [openSearchIndustryModal, setSearchIndustryModal] = useState(false);
  // const [isResctrictionModalVisible, setIsResctrictionModalVisible] = useState(false);
  const [updateScrollValue, setUpdateScrollValue] = useState(false);

  const names = useSelector(selectNames);

  const [selectedIndustries, setSelectedIndustries] = useState([]);

  /** @type {React.MutableRefObject<ColumnTypes.ScrollBarRef>} */
  const scrollbarsRef = useRef();
  /** @type {React.MutableRefObject<number>} */
  const scrollValue = useRef(scrollbarsRef?.current?.scrollTop || 0);
  const { t } = useTranslation();

  // const profile = useSelector(selectProfile);

  useEffect(() => {
    setSortedColumns(data);
  }, [data]);

  // useEffect(() => {
  //   // @ts-ignore
  //   dispatch(loadNames).then((data) => {
  //     setNames(data);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (scrollbarsRef?.current) {
      scrollbarsRef.current.scrollTop = scrollValue.current;
    }
  }, [
    scrollbarsRef?.current?.scrollTop,
    data,
    // isResctrictionModalVisible,
    openModal,
    updateScrollValue,
    openLoadFilterModal
  ]);

  useEffect(() => {
    updateLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettingsChanged]);
  /**
   *
   * @param {object} param0
   * @param {number} param0.oldIndex
   * @param {number} param0.newIndex
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    scrollValue.current = scrollbarsRef?.current?.scrollTop;

    const dataSource = sortedColumns;
    if (oldIndex !== newIndex) {
      const newData = swapItems([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el);
      setSortedColumns(newData);
      onChangeCustomColumns(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = sortedColumns;
    const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const handleOnDelete = useCallback(
    (record) => {
      const res = sortedColumns.filter((el) => el.dataIndex !== record.dataIndex);
      setSortedColumns(res);
      onChangeCustomColumns(res);
    },
    [onChangeCustomColumns, sortedColumns]
  );

  const handleOnEditNestedRow = useCallback(
    (record) => {
      scrollValue.current = scrollbarsRef.current.scrollTop;

      const res = sortedColumns.map((el) => {
        if (el.group === record.parentGroup && Object.prototype.hasOwnProperty.call(el, 'children')) {
          const groupChildren = [];
          // @ts-ignore
          el.children.forEach((c) => {
            if (c.key === record.key) {
              groupChildren.push({
                ...c,
                isVisible: !c.isVisible
              });
            } else {
              groupChildren.push(c);
            }
          });
          return {
            ...el,
            children: groupChildren
          };
        } else {
          return el;
        }
      });
      setSortedColumns(res);
      onChangeCustomColumns(res);
    },
    [onChangeCustomColumns, sortedColumns]
  );

  const handleOnEditVisible = useCallback(
    (record) => {
      const res = sortedColumns.map((el) => {
        if (el.key === record.key) {
          return {
            ...el,
            isVisible: !el.isVisible
          };
        } else {
          return el;
        }
      });
      setSortedColumns(res);
      onChangeCustomColumns(res);

      scrollValue.current = scrollbarsRef?.current?.scrollTop;
      setUpdateScrollValue((prev) => !prev);
    },
    [onChangeCustomColumns, sortedColumns]
  );

  const handleClickOtherFilter = useCallback(
    (record, otherType) => {
      const cols = updateLayout();

      const res = cols.map((el) => {
        if (el.key === record.key) {
          return {
            ...el,
            otherColumns: el.otherColumns.map((oth) => {
              if (oth.type === otherType) {
                return {
                  ...oth,
                  isVisible: !oth.isVisible
                };
              } else {
                return oth;
              }
            })
          };
        } else {
          return el;
        }
      });

      // сохранение позиции скролла страницы при изменении высоты таблицы (показать/скрыть колонки с диаграммами)
      if (otherType.includes('diagram')) {
        dispatch(setScrollPosition(document.documentElement.scrollTop));
        dispatch(setUpdateScrollPosition(true));
      }

      setSortedColumns(res);
      onChangeCustomColumns(res);

      scrollValue.current = scrollbarsRef?.current?.scrollTop;
      setUpdateScrollValue((prev) => !prev);
    },
    [dispatch, onChangeCustomColumns, updateLayout]
  );

  // const getScreeningAvailableChangesRestriction = useCallback(() => {
  //   return profile?.restriction?.data?.screeningAvailableChanges;
  // }, [profile?.restriction?.data?.screeningAvailableChanges]);

  /** @type {(val: ColumnTypes.BaseColumnPrepared[] | null)=>void} */
  const handleChangeVisible = useCallback(
    (val) => {
      scrollValue.current = scrollbarsRef?.current?.scrollTop;

      if (val) {
        onSelectColumns(val);
      }
      setOpenModal((prev) => !prev);
    },
    [onSelectColumns]
  );

  const handleClearFilters = useCallback(() => {
    scrollValue.current = scrollbarsRef?.current?.scrollTop;
    onClearFilters();
  }, [onClearFilters]);

  /**
   * accepts `title`. must be string, empty string or undefiend
   *
   * if `title === ''` invoke onSaveScreen()
   * if `title === undefinde` open SaveScreenModal
   * if `title` not empty invoke onSaveAsScreen(title)
   */
  const handleChangeVisibleSave = useCallback(
    (title) => {
      updateLayout();
      if (title === undefined) {
        // here from saveAs button
        setOpenSaveFilterModal(true);
      } else if (title === '') {
        // here from save button
        onSaveScreen();
      } else if (title?.name) {
        // here from SaveModal.saveBtn
        setOpenSaveFilterModal(false);
        onSaveAsScreen(title);
      } else {
        setOpenSaveFilterModal(false);
      }

      scrollValue.current = scrollbarsRef?.current?.scrollTop;
      setUpdateScrollValue((prev) => !prev);
    },
    [onSaveScreen, onSaveAsScreen, updateLayout]
  );

  const handleChangeVisibleLoad = useCallback((val) => {
    setLoadSaveFilterModal((prev) => !prev);
  }, []);

  const changeFilter = useCallback(
    (name, val, unit, updateLayout = false) => {
      scrollValue.current = scrollbarsRef?.current?.scrollTop;
      return onChangeFilter(name, val, unit, updateLayout);
    },
    [onChangeFilter]
  );

  const handleSelectIndustries = useCallback(
    (values) => {
      scrollValue.current = scrollbarsRef?.current?.scrollTop;

      if (values) {
        setSelectedIndustries(values);
        changeFilter('industry', values, null, true);
      }
      setSearchIndustryModal((prev) => !prev);

      // const screenAvailableChanges = getScreeningAvailableChangesRestriction();
      // if (screenAvailableChanges) {
      //   if (values) {
      //     setSelectedIndustries(values);
      //     changeFilter('industry', values, null, true);
      //   }
      //   setSearchIndustryModal((prev) => !prev);
      // } else {
      //   setIsResctrictionModalVisible((prev) => !prev);
      // }
    },
    // [changeFilter, getScreeningAvailableChangesRestriction]
    [changeFilter]
  );

  const handleSearchIndustry = useCallback(
    (filter) => {
      updateLayout();

      if (filter?.value) {
        setSelectedIndustries(filter.value);
      } else {
        setSelectedIndustries([]);
      }

      setSearchIndustryModal((prev) => !prev);

      scrollValue.current = scrollbarsRef?.current?.scrollTop;
      setUpdateScrollValue((prev) => !prev);
    },
    [updateLayout]
  );

  const handleDeleteFilter = useCallback(
    (columnName) => {
      scrollValue.current = scrollbarsRef?.current?.scrollTop;
      onDeleteFilter(columnName);
    },
    [onDeleteFilter]
  );

  // const handleOnCancelRestrictionModal = useCallback(() => {
  //   setIsResctrictionModalVisible((prev) => !prev);
  //   scrollValue.current = scrollbarsRef?.current?.scrollTop;
  // }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      key: 'sort',
      className: 'drag-visible',
      render: (sort, col) => {
        if (!col.isGroupItem) {
          return <DragHandle />;
        }
      }
    },
    {
      title: () => (
        <Button
          data-test="custom_screen_page_setting_columns_add"
          className={`mg-btn _text ${styles.addColumnButton}`}
          onClick={() => handleChangeVisible(null)}
        >
          <AddColSvg />
          {t('table_add_col')}
        </Button>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (title, col) => {
        if (!col.group) {
          return <p className={styles.titleColumn}>{title}</p>;
        } else {
          return (
            <div style={{ display: 'flex' }}>
              <p className={styles.titleColumn}>{col.groupName}</p>
              <Link
                to={`/resources/articles/definitions#${col.anchor || ''}`}
                data-test="custom_screen_page_setting_columns_hide"
                className="actionIcon"
                target="_blank"
              >
                <QuestionIcon />
              </Link>
            </div>
          );
        }
      }
    },
    {
      title: '',
      key: 'action-quality',
      render: (record, col) => {
        if (
          Object.prototype.hasOwnProperty.call(col, 'otherColumns') &&
          col.otherColumns.some((el) => el.type === 'Quality')
        ) {
          return (
            <a
              data-test="custom_screen_page_setting_columns_quality"
              className={`actionIcon ${
                col.otherColumns.find((el) => el.type === 'Quality').isVisible ? 'selected' : ''
              }`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClickOtherFilter(record, 'Quality');
              }}
            >
              <QualityIcon />
            </a>
          );
        }
      }
    },
    {
      title: '',
      key: 'action-diagram',
      render: (record, col) => {
        if (
          Object.prototype.hasOwnProperty.call(col, 'otherColumns') &&
          col.otherColumns.some((el) => el.type === 'Dynamic diagram')
        ) {
          return (
            <a
              data-test="custom_screen_page_setting_columns_diagram"
              className={`actionIcon ${
                col.otherColumns.find((el) => el.type === 'Dynamic diagram').isVisible ? 'selected' : ''
              }`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClickOtherFilter(record, 'Dynamic diagram');
              }}
            >
              <DiagramIcon />
            </a>
          );
        } else if (
          Object.prototype.hasOwnProperty.call(col, 'otherColumns') &&
          col.otherColumns.some((el) => el.type === 'Range diagram')
        ) {
          return (
            <a
              data-test="custom_screen_page_setting_columns_diagram"
              className={`actionIcon ${
                col.otherColumns.find((el) => el.type === 'Range diagram').isVisible ? 'selected' : ''
              }`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClickOtherFilter(record, 'Range diagram');
              }}
            >
              <DiagramIcon />
            </a>
          );
        }
      }
    },

    {
      title: '',
      key: 'action-hide',
      render: (record, col) => {
        if (!col.isGroupItem) {
          return (
            <a
              data-test="custom_screen_page_setting_columns_hide"
              className={classNames('actionIcon', { selected: record.isVisible })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleOnEditVisible(record);
              }}
            >
              <ShowIcon />
            </a>
          );
        }
      }
    },
    {
      title: '',
      key: 'editable',
      dataIndex: 'editable',
      render: (text, record) => {
        return (
          <a
            data-test="custom_screen_page_setting_columns_delete"
            className="actionIcon"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleOnDelete(record);
            }}
          >
            <TrashIcon />
          </a>
        );
      }
    },
    {
      title: () => (
        <div className={styles.filterTitle}>
          <span>{t('filters_label')}</span>{' '}
          <div className={styles.tableButtons}>
            <>
              {onReset && (
                <Button
                  data-test="custom_screen_page_setting_columns_reset_view"
                  className={`mg-btn _text ${styles.filerButton}`}
                  onClick={() => onReset()}
                >
                  <ClearOutlined style={{ fontSize: '22px' }} />
                  <span style={{ marginLeft: '0' }}>{t('reset_view')}</span>
                </Button>
              )}
              {sortedColumns?.length && (
                <Button
                  data-test="custom_screen_page_setting_columns_clear_filters"
                  className={`mg-btn _text ${styles.filerButton}`}
                  onClick={() => handleClearFilters()}
                >
                  <ClearOutlined style={{ fontSize: '22px' }} />
                  <span style={{ marginLeft: '0' }}>{t('clear_filters')}</span>
                </Button>
              )}
              {hasSave === true && sortedColumns?.length && isSettingsChanged && (
                <Button
                  data-test="custom_screen_page_setting_columns_save_filters"
                  className={`mg-btn _text ${styles.filerButton}`}
                  onClick={() => handleChangeVisibleSave('')}
                >
                  <SaveSvg /> {t('save_screening')}
                </Button>
              )}
              {hasSaveAs === true && sortedColumns?.length && (
                <Button
                  data-test="custom_screen_page_setting_columns_save_filters"
                  className={`mg-btn _text ${styles.filerButton}`}
                  onClick={() => handleChangeVisibleSave()}
                >
                  <SaveSvg /> {t('save_as_screening')}
                </Button>
              )}
            </>
          </div>
        </div>
      ),
      dataIndex: 'filter',
      render: (filter, record) => {
        return (
          <FilterTableRow
            onChange={changeFilter}
            filter={filter}
            col={record}
            onDeleteFilter={() => handleDeleteFilter(filter[0]?.columnName)}
            onOpenModal={
              filter[0]?.columnName === 'industry'
                ? () => {
                    handleSearchIndustry(record.selectedFilter);
                  }
                : (name) => {
                    onSelectFilter(name, filter);
                  }
            }
            onChangeVisibleNestedRow={handleOnEditNestedRow}
            scrollRef={scrollbarsRef}
            scrollValue={scrollbarsRef?.current?.scrollTop}
          />
        );
      }
    }
  ];

  // colunm or column + prop anchor -> names[col.group]
  const completedColumns = useMemo(
    () =>
      sortedColumns.map((col) =>
        !col.group ? col : { ...col, anchor: names?.find((name) => name.name_id.toString() === col.group)?.name }
      ),
    [names, sortedColumns]
  );

  return (
    <div className="settingsColumns">
      <div>
        <Table
          pagination={false}
          dataSource={completedColumns}
          columns={columns}
          rowKey="index"
          rowClassName={(record) => (record ? 'nested-row' : '')}
          // scroll={{ y: 800, x: 1500 }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            },
            table: (props) => (
              <PerfectScrollbar
                options={{ maxScrollbarLength: 150 }}
                containerRef={(el) => (scrollbarsRef.current = el)}
                className={'scroll-container'}
                // style={{ maxHeight: '800px' }}
              >
                <table className={props.className}>{props.children}</table>
              </PerfectScrollbar>
            )
          }}
        />
      </div>

      <SearchColumn visible={openModal} onChangeVisible={handleChangeVisible} columns={sortedColumns} />
      <SearchIndustry
        visible={openSearchIndustryModal}
        onSelectValues={handleSelectIndustries}
        selectedValues={selectedIndustries}
      />
      {/* {isSaveAs ? (
        <SaveAsScreenModal visible={openSaveFilterModal} onChangeVisible={handleChangeVisibleSave} />
      ) : (
        <SaveFilterModal visible={openSaveFilterModal} onChangeVisible={handleChangeVisibleSave} />
      )} */}
      {hasSaveAs && <SaveAsScreenModal visible={openSaveFilterModal} onChangeVisible={handleChangeVisibleSave} />}

      {/* seems that those components is not used */}
      <SavedFilters visible={openLoadFilterModal} onChangeVisible={handleChangeVisibleLoad} />
    </div>
  );
};

export const SettingsColumnsTable = React.memo(SettingsColumnsTableComponent);
