import React, { useState, useCallback, useEffect } from 'react';

import { Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { BaseColumns } from '../base-columns';
import styles from '../SearchColumnModal.module.css';
import { SearchForm } from '../searchForm';
import { SearchResults } from '../searchResults';
import { searchColAsync, searchBaseColAsync } from 'src/app/api/screen';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';

/**
 * @param {object} props
 * @param {boolean} props.visible
 * @param {(columns: ColumnTypes.BaseColumnPrepared[] | null) => void} props.onChangeVisible
 * @param {ColumnTypes.Column[]} props.columns
 */
export const SearchColumn = ({ visible, onChangeVisible, columns }) => {
  const dispatch = useDispatch();

  /** @type {UseHelper.UseStateType<ColumnTypes.BaseColumnPrepared[]>} */
  const [searchResults, setSearchResults] = useState([]);
  /** @type {UseHelper.UseStateType<ColumnTypes.BaseColumnPrepared[]>} */
  const [baseColumns, setBaseColumns] = useState([]);
  /** @type {UseHelper.UseStateType<string | undefined>} */
  const [search, setSearch] = useState();
  const { t } = useTranslation();

  /** @type {() => ColumnTypes.BaseColumnPrepared[]} */
  const getSelectedColumnsKeys = useCallback(() => JSON.parse(sessionStorage.getItem('selected_columns')), []);
  /** @type {(value: ColumnTypes.BaseColumnPrepared[]) => void} */
  const setSelectedColumnsKeys = useCallback(
    (value) => sessionStorage.setItem('selected_columns', JSON.stringify(value)),
    []
  );

  /** @type {(key: string) => boolean} */
  const checkColumnExists = useCallback(
    (key) => {
      return !!columns.find((c) => c.key === key);
    },
    [columns]
  );

  /** @type {(group: string) => boolean} */
  const checkGroupExist = useCallback(
    (group) => {
      return !!columns.find((c) => c.group === group);
    },
    [columns]
  );

  /** @type {(key: string, col: ColumnTypes.ApiColumn) => ColumnTypes.BaseColumn} */
  const getBaseColumn = useCallback((key, col) => {
    const filter = Object.prototype.hasOwnProperty.call(col, 'filter')
      ? [...col.filter.map((filt) => ({ columnName: key, ...filt }))]
      : [];
    const selectedFilter = Object.keys(filter).length === 1 ? filter[0] : null;
    const newCol = {
      dataIndex: key,
      index: key,
      isVisible: !key.includes('diagram') && !key.includes('quality'),
      isAddedColumn: true,
      editable: true,
      sorted: !key.includes('diagram'),
      filtered: false,
      key: key,
      filter,
      selectedFilter
    };
    return newCol;
  }, []);

  /** @type {(res: ColumnTypes.ApiColumns) => ColumnTypes.BaseColumnPrepared[]} */
  const prepareResults = useCallback(
    (res) => {
      const selectedColumnsKeys = getSelectedColumnsKeys();
      const keys = Object.entries(res)
        .sort((a, b) => {
          return b[1].group && a[1].groupOrder - b[1].groupOrder;
        })
        .map((el) => {
          return el[0];
        });
      /** @type {ColumnTypes.BaseColumnPrepared[]} */
      let preparedSearchResults = [];
      keys.forEach((key) => {
        const col = res[key];
        if (!col.group) {
          if (!checkColumnExists(key)) {
            preparedSearchResults.push({
              ...getBaseColumn(key, col),
              title: col.title,
              columnTitle: col.title,
              isGroupItem: false,
              checked: !!selectedColumnsKeys.find((el) => el.key === key)
            });
          }
        } else {
          const colGroupExist = preparedSearchResults.find((el) => el.group === col.group);
          if (!colGroupExist) {
            if (!checkGroupExist(col.group)) {
              preparedSearchResults.push({
                ...getBaseColumn(key, col),
                title: col.title,
                columnTitle: col.groupName,
                group: col.group,
                groupOrder: col.groupOrder,
                otherColumns: [],
                type: col.title,
                groupName: col.groupName,
                checked: !!selectedColumnsKeys.find((el) => el.key === key)
              });
            }
          } else {
            preparedSearchResults = preparedSearchResults.map((item) => {
              if (item.group === col.group) {
                // let visible = true;
                // if (key.includes('diagram') && !!item.otherColumns.find((c) => c.key.includes('diagram'))) {
                //   visible = false;
                // }
                return {
                  ...item,
                  otherColumns: [
                    ...item.otherColumns,
                    {
                      ...getBaseColumn(key, col),
                      // isVisible: visible,
                      title: col.title,
                      columnTitle: col.groupName,
                      isGroupItem: true,
                      group: col.group,
                      groupOrder: col.groupOrder,
                      parentGroup: col.group,
                      type: col.title,
                      groupName: col.groupName,
                      checked: !!selectedColumnsKeys.find((el) => el.key === key)
                    }
                  ]
                };
              } else {
                return item;
              }
            });
          }
        }
      });

      return preparedSearchResults;
    },
    [checkColumnExists, checkGroupExist, getBaseColumn, getSelectedColumnsKeys]
  );

  useEffect(() => {
    if (visible) {
      setSelectedColumnsKeys([]);

      dispatch(searchBaseColAsync())
        // @ts-ignore
        .then(
          /** @type {(res: ColumnTypes.ApiColumns) => void} */
          (res) => {
            const preparedSearchResults = prepareResults(res);
            setBaseColumns(preparedSearchResults);
          }
        )
        .catch((e) => {
          appMessage(typeMessage.ERROR, e.message);
        });
    }
  }, [dispatch, prepareResults, setSelectedColumnsKeys, visible]);

  useEffect(() => {
    if (visible) {
      setBaseColumns((prev) => prev.filter((el) => !columns.map((el) => el.key).includes(el.key)));
    }
  }, [columns, visible]);

  /** @type {(values: string) => void} */
  const handleSearch = useCallback(
    (values) => {
      // @ts-ignore
      dispatch(searchColAsync(values)).then(
        /** @type {(res: ColumnTypes.ApiColumns) => void} */
        (res) => {
          const preparedSearchResults = prepareResults(res);
          setSearchResults(preparedSearchResults);
        }
      );
    },
    [dispatch, prepareResults]
  );

  /** @type {(val: string) => void} */
  const handleChangeSearch = useCallback((val) => {
    setSearch(val);
  }, []);

  const resetState = useCallback(() => {
    setSearch('');
    setSearchResults([]);
    setBaseColumns((prev) => prev.map((el) => ({ ...el, checked: false })));
  }, []);

  const handleOk = () => {
    resetState();
    // @ts-ignore
    onChangeVisible(Array.from(new Set(getSelectedColumnsKeys().map(JSON.stringify))).map(JSON.parse));
  };

  const handleCancel = () => {
    resetState();
    onChangeVisible(null);
  };

  /**
   * @param {ColumnTypes.BaseColumnPrepared} element
   */
  const handleChange = (element) => {
    const selectedColumnsKeys = getSelectedColumnsKeys();
    if (element.checked) {
      setSelectedColumnsKeys([...selectedColumnsKeys, element]);
    } else {
      setSelectedColumnsKeys(selectedColumnsKeys.filter((el) => el.key !== element.key));
    }

    setSearchResults((prev) =>
      prev.map((el) => {
        if (el.key === element.key) {
          return { ...el, checked: element.checked };
        }
        return el;
      })
    );

    setBaseColumns((prev) =>
      prev.map((el) => {
        if (el.key === element.key) {
          return { ...el, checked: element.checked };
        }
        return el;
      })
    );
  };

  return (
    <Modal
      title={''}
      centered
      width={800}
      visible={visible}
      forceRender
      className={`mg-modal`}
      okText={t('add')}
      cancelText={t('cancel')}
      getContainer={'main'}
      data-test={'custom_screen_page_search_column_modal'}
      okButtonProps={{
        className: `mg-btn _green ${styles.button}`,
        // @ts-ignore
        'data-test': 'custom_screen_page_search_column_modal_add'
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        // @ts-ignore
        'data-test': 'custom_screen_page_search_column_modal_cancel'
      }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p className={styles.titleModal}>{t('table_search_column_title')}</p>
      <Divider className={styles.divider} />
      <BaseColumns columns={baseColumns} onChange={handleChange} />
      <SearchForm onSearch={handleSearch} value={search} onChange={handleChangeSearch} />
      <SearchResults results={searchResults} onChange={handleChange} />
    </Modal>
  );
};
