import { createSlice } from '@reduxjs/toolkit';

const screeningSlice = createSlice({
  name: 'screening',
  /** @type {ScreenSlice.ScreeningState} */
  initialState: {
    screeningsDictionary: [],
    systemScreeningsDictionary: [],
    customScreeningsDictionary: [],
    defaultScreening: null,
    accountAlerts: [],
    screeningsTotal: 0,
    scrollPosition: 0,
    updateScrollPosition: false,
    names: []
  },
  reducers: {
    /** @type {(state: ScreenSlice.ScreeningState, action: ProfileSlice.Action<{screenings: ScreenSlice.Screening[], total: number}>) => void} */
    setScreeningsDictionary: (state, action) => {
      state.screeningsDictionary = action.payload.screenings;
      state.screeningsTotal = action.payload.total;

      if (action.payload.screenings !== undefined) {
        state.systemScreeningsDictionary = action.payload.screenings.filter(
          (screen) => screen.type === 'system' || screen.type === 'ideas'
        );
        state.customScreeningsDictionary = action.payload.screenings.filter((screen) => screen.type === 'custom');
      }
    },
    /** @type {(state: ScreenSlice.ScreeningState, action: {payload: ScreenSlice.Screening, type: string})=>void} */
    setDefaultScreening: (state, action) => {
      state.defaultScreening = action.payload;
    },
    /** @type {(state: ScreenSlice.ScreeningState, action: ProfileSlice.Action<object[]>)=>void} */
    setAccountAlerts: (state, action) => {
      state.accountAlerts = action.payload;
    },
    /** @type {(state: ScreenSlice.ScreeningState, action: ProfileSlice.Action<number>)=>void} */
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
    /** @type {(state: ScreenSlice.ScreeningState, action: ProfileSlice.Action<boolean>)=>void} */
    setUpdateScrollPosition: (state, action) => {
      state.updateScrollPosition = action.payload;
    },
    /** @type {(state: ScreenSlice.ScreeningState, action: ProfileSlice.Action<ScreenSlice.Name[]>)=>void} */
    setNames: (state, action) => {
      state.names = action.payload;
    }
  }
});

/** @type {(state: ProfileSlice.State) => ScreenSlice.Screening[]} */
export const selectScreeningsDictionary = (state) => state.screening.screeningsDictionary;
/** @type {(state: ProfileSlice.State) => ScreenSlice.Screening[]} */
export const selectSystemScreeningsDictionary = (state) => state.screening.systemScreeningsDictionary;
/** @type {(state: ProfileSlice.State) => ScreenSlice.Screening[]} */
export const selectCustomScreeningsDictionary = (state) => state.screening.customScreeningsDictionary;
/** @type {(state: ProfileSlice.State) => number} */
export const selectScreeningsTotal = (state) => state.screening.screeningsTotal;
/** @type {(state: ProfileSlice.State) => ScreenSlice.Screening} */
export const selectDefaultScreening = (state) => state.screening.defaultScreening;
/** @type {(state: ProfileSlice.State) => any[]} */
export const selectAccountAlerts = (state) => state.screening.accountAlerts;
/** @type {(state: ProfileSlice.State) => number} */
export const selectScrollPosition = (state) => state.screening.scrollPosition;
/** @type {(state: ProfileSlice.State) => boolean} */
export const selectUpdateScrollPosition = (state) => state.screening.updateScrollPosition;
/** @type {(state: ProfileSlice.State) => ScreenSlice.Name[]} */
export const selectNames = (state) => state.screening.names;
export const {
  setScreeningsDictionary,
  setDefaultScreening,
  setAccountAlerts,
  setScrollPosition,
  setUpdateScrollPosition,
  setNames
} = screeningSlice.actions;

export default screeningSlice.reducer;
