import React, { useState, useEffect, useCallback } from 'react';

import { Modal, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import styles from '../../AccountAlerts.module.css';
import { priceColumns as priceColumnsInitial, alertView } from '../../consts';
import { RowMenuPrice } from '../table-row-menu-price/rowMenu';
import { loadAlertsListByViewAsync, updateAlertAsync, deleteAlertAsync } from 'src/app/api/alerts';
import { AppInputNumber } from 'src/app/components/form/input-price';
import { AppSimpleTable } from 'src/app/components/table';
import { PriceCompanyAlertModel } from 'src/app/models/alerts';
import { selectPriceAlerts, setPriceCompanyAlerts } from 'src/app/slices/alertsSlice';

const { useBreakpoint } = Grid;

export const AccountPriceTab = ({ queryField, queryTemplate, onShowModal, onLoad, handleError }) => {
  const [sort, setSort] = useState({ column: 'companyName', direction: 'ASC' });
  const [pagination, setPagination] = useState({
    limit: 10,
    index: 1,
    total: 0
  });
  const screens = useBreakpoint();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [priceColumns, setPriceColumns] = useState(priceColumnsInitial);
  const priceAlerts = useSelector(selectPriceAlerts);

  const loadAlerts = useCallback(() => {
    dispatch(
      loadAlertsListByViewAsync(
        alertView.price,
        pagination.index - 1,
        pagination.limit,
        sort,
        queryField,
        queryTemplate
      )
    )
      .then((data) => {
        setPagination((prev) => ({
          ...prev,
          total: data.total
        }));
        const res = [...data.items.map((elem) => new PriceCompanyAlertModel(elem))];
        dispatch(setPriceCompanyAlerts({ alerts: res, total: data.totalAlerts }));
      })
      .catch((e) => {
        handleError(e);
      });
  }, [dispatch, pagination.index, pagination.limit, queryField, queryTemplate, sort, handleError]);

  const sendDeleteRequest = useCallback(
    (id) => {
      dispatch(deleteAlertAsync(id))
        .then(() => {
          loadAlerts();
        })
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, loadAlerts, handleError]
  );

  const handleChangeEvent = useCallback(
    (val, column, cik) => {
      switch (val) {
        case 'delete':
          Modal.confirm({
            title: t('alert_delete_warning_title'),
            content: t('alert_delete_warning_content'),
            className: `mg-modal _confirm`,
            okButtonProps: { className: `mg-btn _green-border ${styles.button}` },
            cancelButtonProps: { className: `mg-btn ${styles.button}` },
            onOk: () => {
              const company = priceAlerts.find((el) => el.id === cik);
              sendDeleteRequest(company.alertId);
            }
          });
          break;
        case 'addPrice': {
          const comp = priceAlerts.find((el) => el.id === cik);
          onShowModal('2', comp.companyName + ' (' + comp.tickerName + ')', comp.companyCik, comp.tickerId);
          break;
        }
      }
    },
    [onShowModal, priceAlerts, sendDeleteRequest, t]
  );

  const updateAlert = useCallback(
    (alertId, cRangeEnd, cRangeStart) => {
      const company = priceAlerts.find((el) => el.id === alertId);
      const alertBySchema = {
        rangeStart: cRangeStart,
        rangeEnd: cRangeEnd
      };
      dispatch(updateAlertAsync(company.alertId, { ...alertBySchema, tickerId: company?.tickerId }))
        .then(() => {
          loadAlerts();
        })
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, loadAlerts, priceAlerts, handleError]
  );

  const handleChangePrice = useCallback(
    (val, dataIndex, key) => {
      const company = priceAlerts.find((el) => el.id === key);
      let cRangeStart = null;
      let cRangeEnd = null;
      if (dataIndex === 'rangeStart') {
        cRangeStart = val;
        cRangeEnd = company.rangeEnd;
      } else {
        cRangeEnd = val;
        cRangeStart = company.rangeStart;
      }
      if (
        (cRangeStart === '' && cRangeEnd === null) ||
        (cRangeEnd === '' && cRangeStart === null) ||
        (cRangeEnd === '0' && cRangeStart === null) ||
        (cRangeStart === '0' && cRangeEnd === null)
      ) {
        sendDeleteRequest(company.alertId);
      } else {
        if (cRangeEnd === '' || cRangeEnd === '0') {
          cRangeEnd = null;
        } else if (cRangeStart === '') {
          cRangeStart = null;
        }
        updateAlert(key, cRangeEnd, cRangeStart);
      }
    },
    [updateAlert, sendDeleteRequest, priceAlerts]
  );

  useEffect(() => {
    loadAlerts();
  }, [loadAlerts, onLoad]);

  useEffect(() => {
    setPriceColumns((prev) =>
      prev.map((col, i) => {
        if (col.key === 'rangeStart' || col.key === 'rangeEnd') {
          return {
            ...col,
            editableCellComponent: AppInputNumber,
            onBlurCell: handleChangePrice,
            onPressEnterCell: handleChangePrice
          };
        }
        if (i === prev.length - 1) {
          return { ...col, additionalEventsComponent: RowMenuPrice, onChangeAdditionalEvent: handleChangeEvent };
        }
        return col;
      })
    );
  }, [handleChangeEvent, handleChangePrice]);

  const handleSort = useCallback((sort) => {
    setSort(sort);
  }, []);

  const handleChangePagination = useCallback((page, pageSize) => {
    setPagination({
      index: page,
      limit: pageSize
    });
  }, []);

  return (
    <>
      <span className={styles.tableColumnsTextPrice}>{t('change_price')}</span>
      <AppSimpleTable
        pagination={pagination}
        padding={screens.lg || screens.md ? '60px 106px 0 106px' : screens.sm ? '30px 60px 0 60px' : '0 0 0 0'}
        sort={sort}
        listOfData={priceAlerts}
        onSort={handleSort}
        columns={priceColumns}
        onChangePagination={handleChangePagination}
      />
      {pagination.limit >= pagination.total ? <div style={{ paddingBottom: '71px' }}></div> : ''}
    </>
  );
};
