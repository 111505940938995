import { isNumber } from 'chart.js/helpers';

export const prepareNums = (values, item) => {
  const lookupNumbers = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M', color: 'var(--text-light-gray)' },
    { value: 1e9, symbol: 'B', color: 'var(--title-text-blue)' },
    { value: 1e12, symbol: 'T', color: 'var(--yellow)' },
    { value: 1e15, symbol: 'P', color: 'var(--text-light-gray)' },
    { value: 1e18, symbol: 'E' }
  ];

  if (item) {
    return values.map((val) => {
      return item.value > 1 && isNumber(val) ? val / item.value : val;
    });
  }

  const result = values.map((val) => {
    let item = lookupNumbers[0];
    if (val > 1 || val < -1) {
      item = lookupNumbers
        .slice()
        .reverse()
        .find((elem) => {
          return Math.abs(val) >= elem.value;
        });
    }
    return item.value > 1 ? val / item.value : val;
  });
  return result;
};

export const getValueScale = (value) => {
  const lookupNumbers = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M', color: 'var(--text-light-gray)' },
    { value: 1e9, symbol: 'B', color: 'var(--title-text-blue)' },
    { value: 1e12, symbol: 'T', color: 'var(--yellow)' },
    { value: 1e15, symbol: 'P', color: 'var(--text-light-gray)' },
    { value: 1e18, symbol: 'E' }
  ];
  let item = lookupNumbers[0];
  if (value > 1 || value < -1) {
    item = lookupNumbers
      .slice()
      .reverse()
      .find((elem) => {
        return Math.abs(value) >= elem.value;
      });
  }
  return item;
};
